import { Splide, SplideSlide } from '@splidejs/react-splide';

import Image from 'next/image';
import { LazyImage } from '@components';
import { useStrapiImageFormat } from '@hooks';
import classnames from 'classnames';
import { useMediaQueries } from '@hooks';

export default function Gallery({ list, className }) {
  const { isMobile, isDesktop } = useMediaQueries();

  if (list?.length > 0) {
    return (
      <div
        id="store-gallery"
        className={classnames(
          className,
          'container mx-auto flex flex-col justify-center',
        )}
      >
        <Splide
          tag="section"
          options={{
            perPage: isDesktop ? 4 : 1,
            perMove: 1,
            arrows: false,
            pagination: true,
            type: 'slide',
            autoplay: false,
            gap: isDesktop ? '0.8rem' : 0,
            rewind: true,
            rewindSpeed: 1000,
            rewindByDrag: false,
            heightRatio: 0.3,
            width: '100%',
          }}
        >
          {list?.map(({ attributes }, i) => {
            return (
              <SplideSlide
                key={i}
                className={classnames(
                  'relative',
                  'h-80',
                  'centered',
                )}
              >
                <LazyImage
                  img={useStrapiImageFormat({ img: attributes })}
                  className="aspect-square rounded-md object-cover"
                />
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    );
  }

  return null;
}
