import classNames from 'classnames';
import { CircularProgress, Button } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';

export default function CartActions({
  user,
  total,
  allowPayment,
  cartHasReductions,
  onCreateOrder,
  onShowLogin,
  onShowDiscountStepper,
  onHandlePayment,
  isCreateOrderLoading,
  fixed = false,
}) {
  const { logged, reductionsIDs } = user;
  const { t } = useTranslation('common');

  const buttonCls = classNames(
    'w-full h-12 rounded-sm',
    'text-white uppercase',

    {
      'fixed bottom-0 left-0 right-0 z-[99] h-12 w-full !rounded-none':
        fixed,
    },
  );

  const sx = {
    backgroundColor: '#e17f38 !important',
    '&:hover': {
      backgroundColor: '#da5f06 !important',
    },
  };

  // NOT LOGGED
  if (!logged)
    return (
      <Button
        variant="contained"
        className="w-full h-12 text-white rounded-sm "
        onClick={onShowLogin}
        sx={sx}
      >
        {t('login.sign_in')}
      </Button>
    );

  if (logged) {
    if (cartHasReductions && !allowPayment) {
      return (
        <Button
          key="add-IDs"
          variant="contained"
          endIcon={<Warning fontSize="small" />}
          className={buttonCls}
          onClick={onShowDiscountStepper}
          sx={sx}
        >
          {t('buttons.add_ids')}
        </Button>
      );
    }

    if (
      total === 0 &&
      allowPayment &&
      cartHasReductions &&
      reductionsIDs?.length > 0
    ) {
      return (
        <Button
          variant="contained"
          disabled={isCreateOrderLoading}
          key="create-order"
          onClick={onCreateOrder}
          className={buttonCls}
          sx={sx}
        >
          {isCreateOrderLoading ? (
            <CircularProgress
              size={20}
              thickness={4}
              className="text-white"
            />
          ) : (
            t('buttons.continue')
          )}
        </Button>
      );
    }

    if (allowPayment && total > 0) {
      return (
        <Button
          key="pay"
          onClick={onHandlePayment}
          className={buttonCls}
          sx={sx}
        >
          {t('buttons.pay')}
        </Button>
      );
    }

    if (total === 0 && !cartHasReductions) {
      return (
        <Button
          key="pay-free"
          onClick={onCreateOrder}
          className={buttonCls}
        >
          {t('buttons.continue')}
        </Button>
      );
    }
  }

  return null;
}
