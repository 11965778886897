import React from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'next-i18next';
import LazyImage from 'components/LazyImage/LazyImage';
import Description from '../Description/Description';
import { Button, CircularProgress } from '@mui/material';
import 'dayjs/locale/it';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Products = ({ products, loading }) => {
  const { push } = useRouter();
  const { t } = useTranslation();
  const { locale } = useRouter();

  if (loading) {
    return (
      <div className="w-3/4  mx-auto h-100 flex justify-center items-center">
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className="my-40 flex flex-col justify-center items-center">
        <span className="text-primary-400 uppercase font-bold">
          {t('generic.labels.attention')}
        </span>
        <div className="text-center">
          {t('generic.labels.no_results')}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-2 p-4 mt-10 mb-40 lg:my-0 w-full lg:m-0">
      {products?.map((prod) => {
        const { name, slug, hero, end, store } = prod?.attributes;
        const url = hero?.media?.data?.attributes?.url;
        const status = dayjs(end).isAfter(dayjs())
          ? 'ongoing'
          : 'terminated';
        const timeRemaining =
          status === 'ongoing'
            ? dayjs
                .duration(dayjs(end).diff(dayjs()))
                .locale(locale)
                .humanize()
            : null;

        return (
          <div
            key={prod?.id}
            className="bg-secondary-800/10 flex shadow-card rounded-xl"
          >
            <div className="w-3/5">
              <LazyImage
                className="relative rounded-l-xl  object-cover   h-full"
                img={url}
                skeletonCls="absolute left-0 top-0 right-0 bottom-0"
              />
            </div>

            <div className="p-8 flex flex-col justify-between items-start">
              <div className="space-y-3">
                <div className="font-bold text-primary-400 uppercase mb-2">
                  {name}
                </div>
                <span className="hidden lg:inline">
                  {store?.data?.attributes?.address}
                </span>
                <div className="hidden lg:block">
                  <Description
                    text={hero?.description}
                    chip={false}
                    isCard={false}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div>
                  {t(`generic.labels.${status}`)}
                  {timeRemaining &&
                    ' - ' +
                      `${t('generic.labels.ends_in')} ` +
                      timeRemaining}
                </div>
                <Button
                  variant="text"
                  onClick={() => push(`/eventi/${slug}`)}
                  className="text-primary-400 -ml-2"
                >
                  {t('generic.labels.take_look')}
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Products;
