import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
// import SimpleBar from 'simplebar-react';

export default function Recap({
  product,
  cart,
  date,
  hour,
  fullPrice,
  setCart,
}) {
  //i18n
  const { t } = useTranslation('common');
  const getLabels =
    'drawers.book_product.steps.add_to_cart.labels.';

  const labelCls = classNames(
    'text-sm md:text-base font-medium',
    'text-white ',
  );

  return (
    <>
      <List dense={true} className="px-0 mt-0.5">
        <ListItem className="px-0">
          <ListItemText
            primary={
              <span className={labelCls}>
                {product?.hero?.title}
              </span>
            }
            secondary={t(getLabels + 'where')}
          />
        </ListItem>
        {product?.address && (
          <ListItem className="px-0">
            <ListItemText
              primary={
                <span className={labelCls}>{product?.address}</span>
              }
              secondary={t(getLabels + 'address')}
            />
          </ListItem>
        )}
        <ListItem className="px-0">
          <ListItemText
            primary={
              <div className={labelCls}>
                <span className="mr-2">
                  {dayjs(date).format('DD MMMM YYYY')}
                </span>
                <span>{hour}</span>
              </div>
            }
            secondary={t(getLabels + 'when')}
          />
        </ListItem>
        <ListItem className="px-0">
          <ListItemText
            primary={
              <span className={labelCls}>
                {fullPrice + ' euro'}
              </span>
            }
            secondary={t(getLabels + 'total')}
          />
        </ListItem>
      </List>

      <div
        style={{
          maxHeight: '40vh',
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        }}
      >
        <List dense={true} className="pb-10 pr-4">
          {cart
            .sort(
              (a, b) => a.qty * a.price_sale - b.qty * b.price_sale,
            )
            .map((t, i) => (
              <ListItem
                key={i}
                className="px-0"
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      const newCart = cart.filter(
                        ({ name }) => name !== t.name,
                      );
                      setCart([...newCart]);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <span className="space-x-2">
                      <span>{t.qty}</span>
                      <span>x</span>
                      <span className={labelCls}>{t.name}</span>
                    </span>
                  }
                  secondary={
                    <span>{t.qty * t.price_sale} euro</span>
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>
    </>
  );
}
