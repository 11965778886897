import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import ButtonSlot from './ButtonSlot';

export default function Slots({
  onClickSlot,
  selected = null,
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
}) {
  const { t } = useTranslation('common');

  return (
    <div className="h-full mx-auto">
      {(isLoading || isFetching) && (
        <div
          className={classNames(
            'absolute left-1/2 top-1/2',
            '-translate-x-1/2 -translate-y-1/2 transform',
            'flex items-center justify-center',
            // 'bg-zinc-100 dark:bg-zinc-800',
            'z-50 w-11/12',
          )}
        >
          <CircularProgress sx={{ color: '#faaf40' }} size={20} />
        </div>
      )}

      {/* ERROR */}
      {isError && <code>{JSON.stringify(isError)}</code>}

      {/* SLOTS */}
      {isSuccess && data?.length > 0 && (
        <div
          className={classNames('grid', {
            'grid-cols-5 gap-2': data?.length < 50,
            'grid-cols-6 gap-2':
              data?.length > 50 && data?.length < 70,
            'grid-cols-6 md:grid-cols-7 gap-1': data?.length > 70,
          })}
        >
          {data.map((h, i) => (
            <ButtonSlot
              {...h}
              key={h?.event_hour || i}
              onClick={onClickSlot}
              selected={selected}
            />
          ))}
        </div>
      )}

      {/* NO SLOTS */}
      {isSuccess && data?.length === 0 && (
        <span className="block w-full p-2 text-center">
          {t('drawers.book_product.steps.hour.no_slots')}
        </span>
      )}
    </div>
  );
}
