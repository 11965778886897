import { Cta, Header, Section } from '@components';

import { LazyLoadComponent } from 'react-lazy-load-image-component';
import classnames from 'classnames';

export default function FourRooms({
  title,
  label,
  description,
  cta,
  rooms,
  img,
}) {
  return (
    <Section
      className={classnames('w-full py-32')}
      fullWidth
      noPadding
    >
      <Header
        centered
        title={title}
        label={label}
        description={description}
      />

      <div
        className={classnames(
          'mx-auto flex flex-col items-center justify-center py-12 md:max-w-4xl md:flex-row',
        )}
      >
        {rooms?.map(({ attributes }, i) => {
          const { name } = attributes;
          return (
            <span
              key={i}
              className={classnames(
                'my-2 w-full space-y-8 text-center md:my-0 md:w-1/4',
              )}
            >
              <h4
                className={classnames(
                  'relative space-x-3',
                  'text-3xl',
                  'font-light',
                  'font-voice',
                  'tracking-airy-light',
                  'text-cream-50',
                  'md:max-w-xs',
                  'md:text-3xl',
                  'md:tracking-widest',
                  'md:first-letter:text-4xl',
                )}
              >
                <span
                  className={classnames(
                    'text-sm text-primary-500 lg:text-base',
                  )}
                >
                  {i + 1}
                </span>
                <span>{name}</span>
              </h4>
            </span>
          );
        })}
      </div>

      <div className={classnames('container mx-auto')}>
        <Cta
          className={classnames('mx-auto flex max-w-xs')}
          label={cta?.label}
          href={'/4rooms' || cta?.href}
        />
      </div>

      <span className={classnames('dark-layer')} />
      <LazyLoadComponent>
        <div
          style={{
            backgroundImage: `url(${img?.data?.attributes?.url})`,
          }}
          className={classnames(
            'absolute',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            'bg-center',
            'bg-no-repeat',
            'bg-cover',
            'xl:bg-fixed',
            '-z-20',
          )}
        />
      </LazyLoadComponent>
    </Section>
  );
}
