import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import remarkGfm from 'remark-gfm';

export default function IntroMarkup({ title, text }) {

  if (text && text?.length > 0) {
    return (
      <div className="container max-w-4xl py-16 mx-auto space-y-6">
       {/*  {title && (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className={classnames(
              'font-cinzel',
              'max-w-4xl text-center font-secondary lg:text-5xl',
            )}
          >
            {title}
          </ReactMarkdown>
        )} */}

        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={classnames(
            'strapi-editorial paragraph',
            'max-w-4xl text-center text-base lg:text-2xl',
          )}
        >
          {text}
        </ReactMarkdown>
      </div>
    );

  }
  return null;
}
