import { Header, Section } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import classnames from 'classnames';
import last from 'lodash/last';
import { useMediaQueries } from '@hooks';

export default function Stores({
  title,
  description,
  label,
  list,
}) {
  const { isMobile, isTablet } = useMediaQueries();

  return (
    <Section fullWidth cleared className="lg:px-40" id="stores">
      <Header
        title={title}
        label={label}
        description={description}
      />
      {list?.length > 0 && (
        <Splide
          tag="section"
          className="container pt-8 pb-14"
          options={{
            gap: isMobile ? '2rem' : '1.5rem',
            width: '100vw',
            autoplay: true,
            pagination: true,
            lazyLoad: 'nearby',
            arrows: false,
            //padding: '1rem',
            drag: true,
            perPage: isMobile ? 1 : isTablet ? 2 : 3,
            rewind: true,
            snap: true,
            focus: 0,
            flickMaxPages: 1,
            flickPower: 800,
            arrows: false,
          }}
        >
          {list?.map(({ attributes }, i) => {
            const {
              name,
              slug,
              email,
              address,
              location,
              city,
              img,
              cta,
            } = attributes;

            return (
              <SplideSlide
                key={i}
                className={classnames('overflow-hidden rounded-md')}
              >
                <div
                  className={classnames(
                    'group',
                    'relative flex flex-col',
                    'group w-full overflow-hidden rounded-md',
                  )}
                >
                  <div className="relative flex items-center justify-center w-100 h-110">
                    <div
                      className={classnames(
                        'z-20',
                        'mt-6',
                        'flex',
                        'w-full',
                        'transform',
                        'flex-col',
                        'space-y-2',
                        'rounded-sm',
                        'p-4',
                        'text-center',
                      )}
                    >
                      <Link
                        href={'/' + slug}
                        className={classnames(
                          'flex flex-col',
                          'scale-125',
                          'm-0 p-0',
                          'font-secondary text-3xl font-bold',
                          'text-cream-50',
                        )}
                      >
                        <span>
                          {name
                            ?.split(' ')
                            ?.slice(0, -1)
                            ?.join(' ')}
                        </span>
                        <span className="italic">
                          {last(name?.split(' '))}
                        </span>
                      </Link>
                    </div>

                    <span
                      className={classNames(
                        'absolute bottom-8',
                        'text-cream-50 opacity-90',
                        'label z-20 text-sm tracking-widest',
                        'w-auto',
                        'transition-color duration-500 ease-in-out',
                        "before:absolute before:block before:h-[2px] before:w-full before:content-['']",
                        'before:-bottom-1 before:-left-0.5 before:bg-primary-500',
                        'before:rounded-md',
                        'before:origin-top-left before:scale-x-0 before:group-hover:scale-x-100',
                        'before:transition before:duration-300 before:ease-in-out',
                      )}
                    >
                      {location?.data?.attributes?.name}
                    </span>

                    <span className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-t from-black/50" />

                    <Image
                      fill
                      priority={true}
                      alt="img"
                      src={img?.data?.attributes?.url}
                      sizes="(max-width: 768px) 100vw"
                      className={classnames(
                        'h-full rounded-md',
                        'absolute bottom-0 left-0 right-0 top-0',
                        'transition-all duration-500 ease-in-out',
                        'saturate-75 brightness-75',
                        'group-hover:saturate-100',
                        '-z-5 rounded-sm object-cover',
                        'group-hover:scale-110',
                      )}
                    />
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      )}
      {/* Empty div for padding bottom purpose */}
      <span className="flex w-full h-20" />
    </Section>
  );
}
