import Link from 'next/link';
import classnames from 'classnames';

export default function Cta({
  label = 'Call to Action',
  className,
  fixed,
  href,
  externalLink,
  onClick = null,
}) {
  const onHoverBtnClasses = classnames(
    'absolute inset-0',
    'transition-all duration-[250ms] ease-in-out',
    'w-0 bg-cream-50',
    'rounded-sm',
    'group-hover:w-full',
  );

  const baseButtonClasses = classnames(
    className,
    'rounded-sm',
    'flex items-center justify-center',
    'group shadow',
    'z-50 h-9 w-full overflow-clip',
    'rounded-sm bg-primary-500',
    {
      relative: !fixed,
      fixed: fixed,
    },
  );

  const lableButtonClasses = classnames(
    'relative',
    'text-sm uppercase',
    'text-white lg:text-base',
    'transition-all duration-[250ms] ease-in-out',
    'group-hover:text-black/80',
    'rounded-sm',
  );

  const checkProperHref =
    href?.length > 0 && ['https', 'www'].includes(href);
  


  if (externalLink?.length > 0 || (checkProperHref && !onClick)) {
    return (
      <a
        target="_blank"
        href={externalLink}
        className={baseButtonClasses}
      >
        <div className={onHoverBtnClasses} />
        <span className={lableButtonClasses}>{label}</span>
      </a>
    );
  }

  if (href?.length > 0 && !onClick) {
    return (
      <Link download href={href} className={baseButtonClasses}>
        <div className={onHoverBtnClasses} />
        <span className={lableButtonClasses}>{label}</span>
      </Link>
    );
  }


  if (onClick && typeof onClick === 'function') {
    return (
      <button className={baseButtonClasses} onClick={onClick}>
        <div className={onHoverBtnClasses} />
        <span className={lableButtonClasses}>{label}</span>
      </button>
    ); 
  }

  return null;
}
