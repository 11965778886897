import classnames from "classnames";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";

export default function SwitchLocale({ className }) {
  const { locale, pathname, query, push, asPath } = useRouter();
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    alert
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (locale) => {
    push({ pathname, query }, asPath, { locale, scroll: false });
    handleClose();
  };

  return (
    <div className={classnames(className, "flex")}>
      {['en', 'it'].map((lang, i) => {
        return (
          <button
            key={i}
            className={classnames(
              "w-10 h-10", {
              'opacity-50': locale !== lang,
              'pointer-events-none font-bold': locale === lang,
              'border-r border-white/50': i === 0,
              'hover:text-primary-200 transition-colors ease-in-out duration-300': locale !== lang
            })}
            aria-label="lang"
            id="locale-button"
            aria-haspopup="true"
            onClick={() => handleChange(lang)}
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'locale-menu' : undefined}
          >
            <span className="font-light uppercase lg:text-lg text-md">{lang}</span>
          </button>
        );
      })}
    </div>
  );
}
