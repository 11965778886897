import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Logo } from '@icons';
import classnames from 'classnames';

export default function Divider({ title, subtitle, img }) {
  return (
    <div
      className={classnames(
        'relative',
        'flex',
        'flex-col',
        'w-screen',
        'my-10',
        'overflow-hidden',
        'centered',
        'wrapper',
        'h-110',
      )}
    >
      <Logo
        className={classnames(
          'w-8',
          'h-8',
          'mb-6',
          'fill-cream-50',
        )}
      />
      <div
        className={classnames(
          'flex',
          'flex-col',
          'items-center',
          'space-y-2',
        )}
      >
        <span
          className={classnames(
            'text-xl',
            'font-thin',
            'label',
            'text-cream-50',
            'font-voice',
          )}
        >
          {title}
        </span>
        <span
          className={classnames(
            'font-thin',
            'label',
            'text-cream-50',
            'font-voice',
          )}
        >
          {subtitle}
        </span>
      </div>
      <div className={classnames('dark-layer')} />

      <LazyLoadComponent>
        <div
          style={{
            backgroundImage: `url(${img?.data?.attributes?.url})`,
          }}
          className={classnames(
            'absolute',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            'bg-center',
            'bg-no-repeat',
            'bg-cover',
            'xl:bg-fixed',
            '-z-20',
          )}
        />
      </LazyLoadComponent>
    </div>
  );
}
