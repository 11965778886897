import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { useMediaQueries } from '@hooks';

export default function Info({
  address,
  city,
  phone,
  email,
  hours,
  location,
  map,
  description,
}) {
  const {isMobile} = useMediaQueries();
  const iconsCls = classnames(
    'fill-cream-50',
    'hover:fill-orange-500 hover:scale-125',
    'transition-all ease-in-out duration-800',
  );

  const tooltipCls = classnames(
    'xl:text-lg tracking-wider',
    'font-normal',
  );

  return (
    <section className="relative py-0 overflow-hidden xl:py-16">
      <div
        className={classnames(
          'container relative z-50',
          'flex flex-col items-start justify-around',
          'mx-auto w-full space-y-8 p-8 xl:flex-row xl:space-y-0',
        )}
      >
        <div className="flex flex-col w-full space-y-4 xl:w-auto">
          {address && (
            <div className="space-y-2">
              {city && <span className="label">{city}</span>}
              <h3 className="text-xl tracking-widest xl:text-4xl">
                {address}
              </h3>
            </div>
          )}

          {location && (
            <span className="font-light tracking-wider text-white label">
              {location}
            </span>
          )}
        </div>

        <div className="w-full xl:w-auto">
          {hours?.list?.length > 0 && (
            <ul className="justify-between space-y-2 lg:pl-1 xl:space-y-4">
              {hours?.list?.map(({ day, from, to, id }) => {
                return (
                  <li key={id} className="mb-2 space-y-2">
                    <div className="font-light font-primary xl:mb-0 xl:text-lg">
                      {day}
                    </div>

                    <div className="flex flex-col space-y-1 lg:pl-2">
                      <span className="text-base label">
                        {from}
                      </span>
                      {/*  <span>-</span> */}
                      <span className="text-base label">{to}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}

          <div className="flex flex-row justify-around pt-8 space-x-10">
            {map && (
              <a
                className="block text-sm uppercase cursor-pointer"
                target="_blank"
                href={map}
              >
                <Tooltip
                  title={
                    <span className={tooltipCls}>{address}</span>
                  }
                >
                  <MapIcon
                    className={iconsCls}
                    fontSize={isMobile ? 'medium' : 'large'}
                  />
                </Tooltip>
              </a>
            )}

            {phone && (
              <a
                href={`tel:${phone}`}
                className="block text-sm uppercase cursor-pointer"
              >
                <Tooltip
                  title={
                    <span className={tooltipCls}>{phone}</span>
                  }
                >
                  <PhoneIcon
                    className={iconsCls}
                    fontSize={isMobile ? 'medium' : 'large'}
                  />
                </Tooltip>
              </a>
            )}

            {email && (
              <a
                className="block text-sm uppercase cursor-pointer"
                href={`mailto:${email}`}
              >
                <Tooltip
                  title={
                    <span className={tooltipCls}>{email}</span>
                  }
                >
                  <EmailIcon
                    className={iconsCls}
                    fontSize={isMobile ? 'medium' : 'large'}
                  />
                </Tooltip>
              </a>
            )}
          </div>
        </div>
      </div>

      {isMobile && description && (
        <p
          className={classnames(
            'mx-auto',
            'text-center',
            'paragraph',
            'container p-8',
          )}
        >
          {description}
        </p>
      )}
    </section>
  );
}
