import { Logo, MomentoLogo } from '@icons';

import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from 'next/link';
import Stack from '@mui/material/Stack';
import { SwitchLocale } from '@components';
import classnames from 'classnames';
import { links } from '@mock';
import { useRouter } from 'next/router';
import { useMediaQueries } from '@hooks';

export default function Footer() {
  const { locale } = useRouter();
  const { isTablet, isDesktop } = useMediaQueries();

  return (
    <footer
      id="footer"
      className={classnames(
        'footer',
        'h-[45rem] xl:h-[40rem]',
        'fixed bottom-0 left-0 right-0',
        'border-t-4 border-primary-500',
      )}
    >
      <div className="max-w-6xl px-4 py-12 mx-auto space-y-8 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <Logo className="w-10 h-10 fill-primary-500" />
          <MomentoLogo className="w-40 h-auto fill-cream-50" />

          <div className="flex flex-col items-center py-6">
            <span className="text-xl label text-cream-50">
              {locale === 'it'
                ? 'Ingredienti Semplici'
                : 'Genuine Ingredients'}
            </span>
            <span className="label text-cream-50">
              {locale === 'it'
                ? 'Attimi Speciali'
                : 'Special Moments'}
            </span>
          </div>
          <SwitchLocale className="justify-center" />
        </div>

        {links?.length > 0 && (
          <nav className="mt-12">
            <ul className="flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
              {[
                ...links,
                {
                  path: '/privacy-policy',
                  label: {
                    it: 'Privacy Policy',
                    en: 'Privacy Policy',
                  },
                },
              ]?.map(({ path, label }, i) => {
                return (
                  <li key={i}>
                    <Link
                      href={path}
                      className="text-xl transition-colors duration-200 ease-in-out hover:text-primary-400"
                    >
                      {label[locale]}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        )}

        <ul className="flex justify-center gap-6 mt-12 font-voice md:gap-8">
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/momentofoodit"
            >
              <span className="sr-only">Instagram</span>
              <FacebookIcon />
            </a>
          </li>

          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/momentofoodit"
            >
              <span className="sr-only">Instagram</span>
              <InstagramIcon />
            </a>
          </li>
        </ul>
      </div>
      <Stack
        className="container items-center justify-center w-full mx-auto text-sm text-center "
        spacing={!isDesktop ? 0 : 1}
        direction={!isDesktop ? 'column' : 'row'}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            className="opacity-30 xl:h-10"
          />
        }
      >
        <span className="text-cream-50/50">MOMENTO s.r.l</span>
        <span className="text-cream-50/50">
          Via S.Caboto, 35 Torino (TO)
        </span>
        <span className="text-cream-50/50">
          CF/P.IVA 01973370545
        </span>
        <span className="text-cream-50/50">REA: TO 1253965</span>
        <span className="text-cream-50/50">
          <a href="mailto:info@momentofood.it">
            info@momentofood.it
          </a>
        </span>
      </Stack>
      <div
        className={classnames(
          'h-18 w-full space-x-2 bg-[#1b1b1b]',
          'centered absolute bottom-0 left-0 right-0',
          'text-sm',
        )}
      >
        <span className="text-cream-50/50">
          © Copyright {new Date().getFullYear()} by
        </span>
        <Link
          href="/"
          className="transition-colors duration-500 ease-in-out hover:text-primary-300"
        >
          Momento s.r.l
        </Link>
      </div>
    </footer>
  );
}
