import {
  Actions,
  Calendar,
  Recap,
  SiteSlots,
  Tickets,
} from './index';

import {
  shapeCartClientSide,
  shapeCartServerSide,
  toggleBlurLayout,
} from '@utils';

import { useEffect, useState } from 'react';
import {
  getServiceTicketsByDateAndHour,
  onSetCart,
} from '@services';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import { LimitCartDialog } from '@components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import classNames from 'classnames';
import dayjs from 'dayjs';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '@containers';

export default function DrawerAddToCart({
  open,
  product,
  onClose,
  onOpen = () => null,
  type = 'site',
  forcingDate,
  minDate,
  skipSuggestSiteForService = false,
}) {
  // Data
  const { slug } = product;
  //const slug = 'castello-maniace-siracusa'; //?to test
  const { push, locale } = useRouter();
  const { user, setCart } = useAppContext();
  const { access_token = null } = user;
  const { logged = false } = user;
  const { t } = useTranslation('common');

  // State
  const [cart_, setCart_] = useState([]);
  const [hour, setHour] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isTicketsSuccess, setIsTicketsSuccess] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(true);
  const [date, setDate] = useState(forcingDate || new Date());
  const [fullPrice, setFullPrice] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [skipCrossSelling, setSkipCrossSelling] = useState(
    !skipSuggestSiteForService,
  );
  const [error, setError] = useState(null);

  // Events
  const onClickCart = () => push('/carrello');
  const onShowMaxAddAlert = () => setShowAlert(true);
  const onResetStep = () => setActiveStep(null);
  const onSkipCrossSelling = () => setSkipCrossSelling(true);

  const onBackToLastStep = () => {
    setFullPrice(0);
    setCart_([]);
    setIsTicketsLoading(false);
    setIsTicketsSuccess(false);
    setActiveStep(0);
    setSkipCrossSelling(true);
    setTickets([]);
    setError(false);
  };

  // Utils
  const steps = [
    {
      label: t('drawers.book_product.steps.day.name'),
    },
    {
      label: t('drawers.book_product.steps.hour.name'),
    },
    {
      label: t('drawers.book_product.steps.tickets.name'),
    },
  ];

  useEffect(() => {
    if (forcingDate !== null) {
      setDate(forcingDate);
      setActiveStep(1);
    } else {
      setActiveStep(0);
      setDate(new Date());
    }
  }, [forcingDate]);

  // OPEN/CLOSE DRAWER
  useEffect(() => {
    toggleBlurLayout(['blur-md'], open);

    return () => {
      if (!forcingDate) {
        setHour(null);
        setFullPrice(0);
        setActiveStep(0);
        setDate(new Date());
        setCart_([]);
        setSkipCrossSelling(true);
        setSubmit(false);
        setIsTicketsSuccess(false);
        setError(false);
      }
    };
  }, [open]);

  useEffect(() => {
    return () => {
      setHour(null);
      setFullPrice(0);
      setActiveStep(0);
      setDate(new Date());
      setCart_([]);
      setSkipCrossSelling(true);
      setSubmit(false);
      setIsTicketsSuccess(false);
      setError(false);
    };
  }, []);

  // QUANTITY & FULL PRICE
  useEffect(() => {
    const hasNoStep = isNil(activeStep);
    const hasNoCart = cart_?.length === 0;
    const hasDateAndHour = date && hour;

    if (hasNoStep && hasDateAndHour && hasNoCart) {
      setActiveStep(2);
    }

    setFullPrice(
      reduce(
        cart_,
        (sum, { price_sale, qty }) => sum + price_sale * qty,
        0,
      ),
    );
  }, [cart_]);

  useEffect(() => {
    const isServiceType = type === 'service';
    const hasSiteSlug = product?.site?.data?.attributes?.slug;

    if (
      !skipSuggestSiteForService &&
      isServiceType &&
      skipCrossSelling &&
      hasSiteSlug &&
      date &&
      hour &&
      cart_?.length > 0 &&
      activeStep !== 2
    ) {
      setSkipCrossSelling(false);

      handleGetTicketsSite({
        slug: hasSiteSlug,
        hour,
        date: dayjs(date).format('YYYY-MM-DD'),
      });
    }
  }, [
    cart_,
    type,
    date,
    hour,
    product,
    activeStep,
    skipSuggestSiteForService,
  ]);

  useEffect(() => {
    if (date && hour && type) {
      const isSiteType = type === 'site';
      const formattedDate = dayjs(date).format('YYYY-MM-DD');

      const params = {
        slug,
        hour,
        date: formattedDate,
      };
      if (isSiteType) handleGetTicketsSite(params);
    }
  }, [date, hour, type]);

  // On Get Tickets Site
  const handleGetTicketsSite = async (params) => {
    try {
      setIsTicketsLoading(true);
      const { success, data } =
        await getServiceTicketsByDateAndHour({
          ...params,
          locale,
        });
      setTickets(data);
      setIsTicketsSuccess(true);
      setIsTicketsLoading(false);

      return { success, data };
    } catch (error) {
      console.error('handleGetTicketsSite', error);
      setIsTicketsLoading(false);
    }
  };

  // ON NEXT STEP
  const handleNext = () => {
    try {
      const totalSteps = steps?.length - 1;
      if (activeStep < totalSteps) {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      console.error('handleNext', error);
    }
  };

  // ON PREVIOUS STEP
  const handleBack = () => {
    try {
      if (activeStep <= 0) return;

      if (activeStep === 2) {
        setFullPrice(0);
        setCart_([]);
      }

      setActiveStep((prevStep) => prevStep - 1);
      setSkipCrossSelling(true);
      setIsTicketsLoading(false);
      setIsTicketsSuccess(false);
      setError(false);
    } catch (error) {
      console.error('handleBack', error);
    }
  };

  // ON SAVE CART
  const onSaveCart = async () => {
    try {
      if (logged) {
        // Add to server cart
        const products = shapeCartServerSide({
          cart: cart_,
          date,
          hour,
        });
        const { data } = await onSetCart(
          {
            products,
          },
          access_token,
        );
        if (data?.success && data?.status === 'success') {
          setSubmit(true);
        } else {
          console.error('onSaveCart FAIL ⚠️');
          setError(true);
          setSubmit(false);
        }
      } else {
        // Add to client cart: DA FARE MEGLIO
        const formattedCart = shapeCartClientSide({
          cart: cart_,
          date,
          hour,
          product: {
            name: product?.name || product?.hero?.title,
            city: product?.city?.data?.attributes?.name,
            address: product?.address,
            region: product?.region,
            canonical_name: product?.slug,
            in_concession: cart_?.[0]?.in_concession,
            category: cart_?.[0]?.product_category,
            // Ramiro ficcati le mani in culo
            site: has(product, 'site.data.attributes')
              ? {
                  name:
                    product?.site.data.attributes?.name ||
                    product?.site.data.attributes?.hero?.title,
                  city: product?.site.data.attributes?.city?.data
                    ?.attributes?.name,
                  address: product?.site.data.attributes?.address,
                  region: product?.site.data.attributes?.region,
                  canonical_name:
                    product?.site.data.attributes?.slug,
                  in_concession: cart_?.[0]?.in_concession,
                  // category: cart?.[0]?.category,
                }
              : {
                  name: product?.name || product?.hero?.title,
                  city: product?.city?.data?.attributes?.name,
                  address: product?.address,
                  region: product?.region,
                  canonical_name: product?.slug,
                  in_concession: cart_?.[0]?.in_concession,
                  // category: cart_?.[0]?.category,
                },
          },
        }).map((p, i) => ({
          ...p,
          id: Date.now() + i,
        }));

        setCart(formattedCart);
        setSubmit(true);
      }
    } catch (error) {
      console.error('onSaveCart', error);
      setSubmit(true);
    }
  };

  // ON CHANGE DATA
  const onChangeDate = (date) => {
    try {
      setDate(date);
      setHour(null);
      setFullPrice(0);
      handleNext(false);
      setIsTicketsLoading(false);
      setIsTicketsSuccess(false);
    } catch (error) {
      console.error('onChangeDate', error);
    }
  };

  // ON CLICK SLOT
  const onClickSlot = (hour) => {
    try {
      if (hour) {
        setHour(hour);
        setFullPrice(0);
        handleNext(false);
      }
    } catch (error) {
      console.error('onClickSlot', error);
    }
  };

  const onSetDrawerLabel = () => {
    try {
      const langPath = 'drawers.book_product.steps.';

      switch (activeStep) {
        case 0:
          return t(langPath + 'day.chip');
        case 1:
          return t(langPath + 'hour.chip');
        case 2:
          return t(langPath + 'tickets.chip');
        default:
          if (submit) {
            return error
              ? 'Error'
              : t(langPath + 'congratulations.chip');
          }

          return skipCrossSelling
            ? t(langPath + 'add_to_cart.chip')
            : t(langPath + 'see_also.chip');
      }
    } catch (error) {
      console.error('onSetDrawerLabel', error);
      return '';
    }
  };

  const renderActiveSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <Calendar
            date={dayjs(date)}
            minDate={dayjs(minDate)}
            onChangeDate={onChangeDate}
          />
        );
      case 1:
        if (type === 'site') {
          return (
            <SiteSlots
              slug={slug}
              onClickSlot={onClickSlot}
              date={dayjs(date).format('YYYY-MM-DD')}
            />
          );
        }
        break;
      case 2:
        return (
          <Tickets
            tickets={tickets}
            currentCart={cart_}
            fullPrice={fullPrice}
            setFullPrice={setFullPrice}
            onChange={(t) => {
              if (!isEqual(cart_, t)) setCart_(t);
            }}
            isTicketsSuccess={isTicketsSuccess}
            isTicketsLoading={isTicketsLoading}
            onLimitReachedCallback={onShowMaxAddAlert}
          />
        );
      default:
        return null;
    }
  };

  const ShowRecap = (type) =>
    type === 'site' &&
    skipCrossSelling &&
    cart_?.length > 0 && (
      <Recap
        key="recap"
        hour={hour}
        date={date}
        cart={cart_}
        product={product}
        setCart={setCart_}
        fullPrice={fullPrice}
      />
    );
  useEffect(() => {
    if (type === 'service' && tickets?.length <= 0) {
      setSkipCrossSelling(true);
    }
  }, [tickets]);
  return (
    <>
      <SwipeableDrawer
        open={open}
        elevation={0}
        anchor="right"
        onOpen={onOpen}
        onClose={onClose}
        variant="temporary"
        hideBackdrop={false}
        disableSwipeToOpen={true}
        ModalProps={{ keepMounted: false }}
        transitionDuration={{ enter: 200, exit: 300 }}
        sx={{
          '& .MuiDrawer-paper': {
            // background: 'black',
            border: 'none',
          },
        }}
      >
        <div
          className={classNames(
            'relative',
            'flex flex-col',
            'select-none',
            'h-screen w-screen md:w-[650px] 3xl:w-[800px]',
            // 'bg-zinc-100 dark:bg-zinc-800',
            'z-100 overflow-hidden shadow-2xl',
            'px-4 lg:px-6',
          )}
        >
          <div className="h-screen">
            {/* <code>
              <pre>
                {JSON.stringify(
                  {
                    type,
                    isTourLoading,
                    isTourSuccess,
                    isTicketsSuccess,
                    isTicketsLoading,
                    activeStep,
                    skipCrossSelling,
                  },
                  null,
                  2,
                )}
              </pre>
            </code> */}

            <div
              className={classNames(
                'relative',
                'w-full h-10',
                'flex items-center justify-center',
              )}
            >
              <IconButton
                color="inherit"
                component="span"
                size="small"
                onClick={onClose}
                className={classNames(
                  'text-xl',
                  'absolute left-0 top-4',
                  'flex items-center justify-center',
                )}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>

            <div className="flex flex-col pb-0 mb-4 md:mb-8">
              <Divider className="font-semibold uppercase text-primary">
                <Chip
                  variant="outlined"
                  label={onSetDrawerLabel()}
                  className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
                  sx={{
                    '& .MuiChip-label': {
                      padding: '16px 32px !important',
                    },
                  }}
                />
              </Divider>
            </div>

            {!isNil(activeStep) && (
              <div className="w-full mx-auto">
                {renderActiveSteps()}
              </div>
            )}

            {isNil(activeStep) && !submit && !error && (
              <>{ShowRecap(type)}</>
            )}

            {/* POST ADD TO CART MESSAGE */}
            {submit && !error && (
              <div className="mt-6">
                <div className="flex flex-col items-center justify-center w-full spacey-3 ">
                  <Alert
                    severity="success"
                    className="w-full bg-transparent"
                  >
                    <AlertTitle className="text-xl">
                      {t(
                        'drawers.book_product.steps.congratulations.title',
                      )}
                    </AlertTitle>
                    <ul>
                      <li>
                        {t(
                          'drawers.book_product.steps.congratulations.timing',
                        )}
                      </li>
                    </ul>
                  </Alert>
                  <Button
                    fullWidth
                    color="success"
                    key="payment"
                    className="max-w-sm mx-auto mt-4"
                    variant="outlined"
                    onClick={onClickCart}
                  >
                    {t('buttons.proceed_payment')}
                  </Button>
                </div>
              </div>
            )}

            {!submit && error && (
              <div className="mt-6">
                <div className="flex flex-col items-center justify-center w-full spacey-3">
                  <Alert
                    severity="error"
                    className="w-full bg-transparent"
                    action={
                      <Button
                        size="small"
                        color="error"
                        className="hidden px-4 md:flex"
                        onClick={onBackToLastStep}
                      >
                        Reset
                      </Button>
                    }
                  >
                    <AlertTitle className="text-xl">
                      Error
                    </AlertTitle>
                    <ul>
                      <li>Please, try again</li>
                    </ul>
                  </Alert>
                </div>
              </div>
            )}

            {/* BUTTONS */}
            <div
              className={classNames(
                'h-12 w-full md:h-14',
                'flex flex-row z-50',
                'bg-inherit',
                'absolute bottom-0 left-0 right-0',
              )}
            >
              <Actions
                activeStep={activeStep}
                cart={cart_}
                error={error}
                handleBack={handleBack}
                isAddToCartLoading={false}
                isTicketsSuccess={isTicketsSuccess}
                isTourSuccess={true}
                onBackToLastStep={onBackToLastStep}
                onClickCart={onClickCart}
                onResetStep={onResetStep}
                onSaveCart={onSaveCart}
                onSkipCrossSelling={onSkipCrossSelling}
                skipCrossSelling={skipCrossSelling}
                skipSuggestSiteForService={
                  skipSuggestSiteForService
                }
                submit={submit}
                tickets={tickets}
                tour={[]}
                type={type}
              />
            </div>
          </div>
        </div>
      </SwipeableDrawer>

      {/* <LimitCartDialog
        open={showAlert}
        showGoToCart={false}
        onClose={onCloseMaxAddAlert}
        maxQuantityAllowed={maxQuantityAllowed}
        isService={
          false
          // isNil(activeStep) && isTourSuccess && tour?.length > 0
        }
      /> */}
    </>
  );
}
