import axios from 'axios';
import { stringify } from 'qs';

const baseUrl = process.env.NEXT_PUBLIC_STRAPI;
const publicationState = 'live'; //_preview || _live //?to test
const prepareHeaders = (access_token) => {
  const headers = {};
  const token = access_token;
  if (token) {
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export const getStrapiEvents = async (filters, pagination) => {
  const qs = stringify(
    {
      publicationState: publicationState,
      populate: 'deep',
      filters,
      pagination,
      locale: 'it',
    },
    { encodeValuesOnly: true },
  );
  const path = `${baseUrl}/events?${qs}`;
  let response = {
    data: null,
    error: false,
  };
  stringify;

  try {
    // const headers = prepareHeaders(access_token);
    await axios
      .get(path)
      .then((res) => {
        response = {
          ...response,
          data: res.data?.data || [],
          meta: res?.data.meta,
        };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getScheduleServiceBySlug', error);
    return { error };
  }
  return response;
};

export const getStrapiCities = async () => {
  const qs = stringify(
    {
      publicationState: publicationState,
      locale: 'it',
    },
    { encodeValuesOnly: true },
  );
  const path = `${baseUrl}/cities?${qs}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    // const headers = prepareHeaders(access_token);
    await axios
      .get(path)
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getScheduleServiceBySlug', error);
    return { error };
  }
  return response;
};

export const getStrapiLocations = async () => {
  const qs = stringify(
    {
      locale: 'it',
      publicationState: publicationState,
    },
    { encodeValuesOnly: true },
  );
  const path = `${baseUrl}/locations?${qs}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    // const headers = prepareHeaders(access_token);
    await axios
      .get(path)
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getScheduleServiceBySlug', error);
    return { error };
  }
  return response;
};
