import classnames from 'classnames';
import { RevealWrapper } from 'next-reveal';
export default function Section({
  children,
  light,
  className,
  noPadding = false,
  fullWidth = false,
  cleared = false,
  id = false,
}) {
  return (
    <section
      {...(id && { id })}
      className={classnames(
        className,
        'section relative overflow-clip',
        {
          'container mx-auto': !fullWidth && !light,
          'mx-auto bg-cream-50': light,
          'bg-[#282828]': cleared,
          'py-16 lg:py-48': !noPadding,
        },
      )}
    >
      {children}
    </section>
  );
}
