import React from 'react';
import { Chip } from '@mui/material';
import Image from 'next/image';

const Sponsors = ({ logos }) => {
  return (
    <div className="bg-secondary-800/10 p-8   shadow-card rounded-xl flex flex-col items-start space-y-6">
      <Chip
        variant="outlined"
        label={'sponsors'}
        className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
        sx={{
          '& .MuiChip-label': {
            padding: '16px 32px !important',
          },
        }}
      />
      <div>
        {logos?.data?.map((elem) => (
          <div key={elem?.id} className="w-24 h-24">
            <Image
              src={elem?.attributes?.url}
              alt={elem?.attributes?.name}
              fill
              className=" relative object-contain w-24 h-24"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
