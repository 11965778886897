import {
  Cta,
  Header,
  Section,
} from '@components';

import classnames from "classnames";

export default function CallToBooking({
  title,
  label,
  description,
  ctas,
}) {
  return (
    <Section cleared fullWidth>
      <div
        className={classnames(
          'container',
          'flex',
          'flex-col',
          'items-center',
          'px-4',
          'py-12',
          'mx-auto',
          'text-center',
        )}
      >
        <Header
          centered
          title={title}
          label={label}
          description={description}
          watermark="Booking"
        />

        {ctas?.length > 0 && (
          <div
            className={classnames(
              'flex',
              'flex-col',
              'mt-12',
              'space-y-6',
              'md:flex-row',
              'md:space-x-6',
              'md:space-y-0',
            )}
          >
            {ctas?.map(({label, href}, i) => {
              return (
                label && href && <Cta
                  key={i}
                  className="flex w-full cta md:w-auto"
                  label={label}
                  href={href}
                />
              );
            })}
          </div>
        )}        
      </div>
    </Section>
  );
}