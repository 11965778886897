import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { registerUser } from '@services';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = ({ onClose = () => {} }) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    language_code: locale,
    newsletter: 0,
    profilation: 0,
    anonymization: 0,
    privacy: 0,
  });
  const onForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCaptcha(true);
  };
  const onSubmit = async (payload) => {
    const { data, error } = await registerUser({ data: form });
    if (data?.success) {
      const { data: res } = data;
    }
    onClose();
  };
  return (
    <>
      {showCaptcha && (
        <div className="flex justify-center">
          <ReCAPTCHA
            hl={locale}
            className="my-6 w-full bg-transparent first:w-fit"
            onChange={async (e) => {
              onSubmit({
                ...form,
                'g-recaptcha-response': e,
                domain: window.location.hostname,
              });
            }}
            sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
          />
        </div>
      )}

      {!showCaptcha && (
        <Box
          id="form_sign_up"
          component={'form'}
          onSubmit={onForm}
          sx={{
            '& .MuiInputBase-root, .MuiInputLabel-root': {
              color: '#fff !important',
            },
          }}
        >
          <TextField
            className="my-1"
            fullWidth
            label={t('forms.name')}
            margin="dense"
            name="email"
            required
            size={'small'}
            type="text"
            value={form?.first_name}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                first_name: res.target.value,
              });
            }}
          />
          <TextField
            className="my-1"
            fullWidth
            label={t('forms.surname')}
            margin="dense"
            name="email"
            required
            size={'small'}
            type="text"
            value={form?.last_name}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                last_name: res.target.value,
              });
            }}
          />

          <TextField
            className="my-1"
            fullWidth
            label="Email"
            margin="dense"
            name="email"
            required
            size={'small'}
            type="email"
            value={form?.email}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                email: res.target.value,
              });
            }}
          />
          <TextField
            className="my-1"
            fullWidth
            label="Password"
            margin="dense"
            name="password"
            required
            size={'small'}
            type={!showPassword ? 'password' : 'text'}
            value={form?.password}
            error={form?.password_confirmation !== form?.password}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                password: res.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <>
                  {form?.password?.length >= 1 && (
                    <IconButton
                      onClick={() =>
                        setShowPassword((show) => !show)
                      }
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff color="primary" />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
          <TextField
            className="my-1"
            fullWidth
            label={t('forms.confirm_password')}
            margin="dense"
            name="password"
            required
            size={'small'}
            error={form?.password_confirmation !== form?.password}
            type={!showConfPassword ? 'password' : 'text'}
            value={form?.password_confirmation}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                password_confirmation: res.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <>
                  {form?.password_confirmation?.length >= 1 && (
                    <IconButton
                      onClick={() =>
                        setShowConfPassword((show) => !show)
                      }
                      edge="end"
                    >
                      {showConfPassword ? (
                        <VisibilityOff color="primary" />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
          <div className="flex items-center my-3">
            <Checkbox
              checked={form?.privacy === 1}
              className="-ml-3"
              onChange={(res) => {
                setForm({
                  ...form,
                  privacy: res.target.checked ? 1 : 0,
                });
              }}
            />
            <span className="text-xs">
              {t('forms.accept_privacy_1')}{' '}
              <a
                href={'/privacy-policy'}
                target="_blank"
                className="font-bold underline underline-offset-2 "
              >
                Privacy Policy
              </a>
              {t('forms.accept_privacy_2')}
            </span>
          </div>
          <FormControl>
            <span className="text-base ">
              {t('forms.private.newsletter.text')}
            </span>

            <RadioGroup
              row
              name="policy-newsletter-group"
              aria-labelledby="policy-newsletter"
            >
              <FormControlLabel
                label={t('forms.yes')}
                control={
                  <Radio
                    checked={Boolean(form.newsletter)}
                    size="small"
                    value={+true}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        newsletter: +true,
                        profilation: +true,
                        anonymization: +true,
                      });
                    }}
                  />
                }
              />
              <FormControlLabel
                label={t('forms.no')}
                control={
                  <Radio
                    checked={Boolean(!form.newsletter)}
                    size="small"
                    value={+false}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        profilation: +false,
                        newsletter: +false,
                        anonymization: +false,
                      });
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
          <Button
            disabled={
              form.privacy === 0 ||
              form?.password_confirmation !== form?.password
            }
            form={'form_sign_up'}
            fullWidth
            type="submit"
            variant="contained"
            onSubmit={onForm}
          >
            {t('login.sign_up')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Register;
