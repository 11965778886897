import {
  Header,
  Register_Form,
  Login_Form,
  Recover_Form,
} from '@components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

const AuthWall = () => {
  const { t } = useTranslation('common');
  const [type, setType] = useState('sign_in');
  const handleClose = () => {};
  const formByType = () => {
    switch (type) {
      case 'sign_in':
        return (
          <Login_Form
            onSignUp={() => setType('sign_up')}
            onRecover={() => setType('forgot_password')}
            onClose={handleClose}
          />
        );
      case 'sign_up':
        return <Register_Form />;
      case 'forgot_password':
        return <Recover_Form onClose={handleClose} />;
      default:
        return <></>;
    }
  };
  return (
    <div className="relative flex flex-col items-center justify-center mt-10 mb-16">
      <Header
        title={t('components.auth_wall.title')}
        label={t('components.auth_wall.label')}
        description={t('components.auth_wall.text')}
        centered
      />
      {formByType()}
    </div>
  );
};

export default AuthWall;
