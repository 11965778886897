import { Cta, Header, Section } from '@components';

import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import TapasIcon from '@mui/icons-material/Tapas';
import WineBarIcon from '@mui/icons-material/WineBar';
import classnames from 'classnames';

export default function ServicesOffered({
  title,
  label,
  description,
  watermark = 'Servizi',
  services = [],
  cta = null
}) {
  return (
    <Section fullWidth>
      <Header
        centered
        fullWidth
        title={title}
        label={label}
        watermark={watermark}
        description={description}
      />

      {services?.length > 0 && (
        <div
          className={classnames(
            'container',
            'mt-8',
            'mx-auto',
            'flex flex-col lg:flex-row',
            'justify-center',
            'gap-8',
            'bg-[#0000]',
            'pt-10',
            'lg:gap-8',
          )}
        >
          {services?.map(({ attributes }, i) => {
            const { type, slug } = attributes;

            return (
              <div
                key={i}
                className={classnames(
                  'relative',
                  'flex',
                  'flex-col',
                  'px-8',
                  'py-8',
                  'space-y-4',
                )}
              >
                {[
                  slug === 'breakfast' && (
                    <BakeryDiningIcon
                      key='breakfast'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),

                  slug === 'lunches' && (
                    <DinnerDiningIcon
                      key='lunches'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),
                  slug === 'aperitifs' && (
                    <WineBarIcon
                      key='aperitifs'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),
                  slug === 'dinners' && (
                    <LocalPizzaIcon
                      key='dinners'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),
                  slug === 'events' && (
                    <LocalBarIcon
                      key='events'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),
                  slug === 'tastings' && (
                    <TapasIcon
                      key='tastings'
                      fontSize='large'
                      className='w-40 mx-auto'
                    />
                  ),
                ]}
                <h2
                  className={classnames(
                    'text-sm',
                    'text-center',
                    'md:text-base',
                    'label',
                    'text-cream-50',
                  )}
                >
                  {type}
                </h2>
              </div>
            );
          })}
        </div>
      )}

      {cta?.label && cta?.href &&
        <div className='container'>
          <Cta
            href={cta?.href}
            label={cta?.label}
            className='container max-w-xs p-4 mx-auto mt-16'
          />
        </div>}
    </Section>
  );
}
