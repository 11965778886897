import {
  ArrowBackIos,
  ArrowBackIosRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { updateUserData, getUserData } from '@services';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const UpdatedUserProfileForm = ({
  id,
  access_token,
  className,
  onError,
  onBack,
}) => {
  const { push, locale } = useRouter();
  const { t } = useTranslation('common');

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    old_password: null,
    new_password: null,
    new_password_confirmation: null,
    language_code: locale,
    newsletter: user?.newsletter || 0,
    profilation: user?.profilation || 0,
  });

  const onGetUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await getUserData({ id: id }, access_token);

      if (data?.success) {
        setUser(data?.data);
        setIsSuccess(true);
      } else {
        setUser(null);
        setIsSuccess(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('onGetUser', error);
      setUser(null);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const { data: res } = await updateUserData(
        { id: id, data: formValues },
        access_token,
      );
      if (res?.success) {
        push('/');
      }
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  useEffect(() => {
    onGetUser();
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="flex items-center justify-center w-full py-24">
          <CircularProgress thickness={4} size={20} />
        </div>
      )}
      {isSuccess && (
        <Box
          component={'form'}
          id="user"
          className={['space-y-6', className].join(' ')}
          onSubmit={onSubmit}
        >
          <TextField
            defaultValue={formValues.first_name}
            fullWidth
            label={t('forms.name')}
            placeholder={formValues.first_name}
            required
            type="text"
            variant="outlined"
            onChange={(res) =>
              setFormValues({
                ...formValues,
                first_name: res.target.value,
              })
            }
          />
          <TextField
            defaultValue={formValues.last_name}
            placeholder={formValues.last_name}
            fullWidth
            label={t('forms.surname')}
            required
            type="text"
            variant="outlined"
            onChange={(res) =>
              setFormValues({
                ...formValues,
                last_name: res.target.value,
              })
            }
          />
          <TextField
            fullWidth
            label={t('forms.old_password')}
            required
            type="password"
            variant="outlined"
            value={formValues?.old_password}
            onChange={(res) =>
              setFormValues({
                ...formValues,
                old_password: res.target.value,
              })
            }
          />
          <TextField
            fullWidth
            label={t('forms.new_password')}
            required
            type="password"
            variant="outlined"
            value={formValues?.new_password}
            onChange={(res) =>
              setFormValues({
                ...formValues,
                new_password: res.target.value,
              })
            }
          />

          <TextField
            fullWidth
            label={t('forms.confirm_new_password')}
            required
            type="password"
            variant="outlined"
            value={formValues?.new_password_confirmation}
            error={
              formValues?.new_password_confirmation !==
              formValues?.new_password
            }
            helperText="Password confirmation and Password must match"
            onChange={(res) =>
              setFormValues({
                ...formValues,
                new_password_confirmation: res.target.value,
              })
            }
          />

          <FormControl className="px-2">
            <span className="text-xs md:text-sm">
              {t('forms.private.newsletter.text')}
            </span>

            <RadioGroup
              row
              className="my-2"
              name="policy-newsletter-group"
              aria-labelledby="policy-newsletter"
            >
              <FormControlLabel
                label={t('forms.yes')}
                control={
                  <Radio
                    size="small"
                    value={true}
                    checked={Boolean(formValues.newsletter.value)}
                  />
                }
                onChange={(v) =>
                  setFormValues({
                    ...formValues,
                    newsletter: true,
                  })
                }
              />
              <FormControlLabel
                label={t('forms.no')}
                control={
                  <Radio
                    size="small"
                    value={false}
                    checked={Boolean(!formValues.newsletter.value)}
                    onChange={(v) =>
                      setFormValues({
                        ...formValues,
                        newsletter: false,
                      })
                    }
                  />
                }
              />
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            form="user"
            onSubmit={onSubmit}
            fullWidth
            className="text-white"
            sx={{
              backgroundColor: '#e17f38 !important',
              '&:hover': {
                backgroundColor: '#da5f06 !important',
              },
            }}
          >
            SUBMIT
          </Button>
          <div className="flex justify-center w-full">
            <Button
              variant="text"
              onClick={onBack}
              className="text-white"
            >
              <ArrowBackIosRounded
                fontSize="small"
                className="mr-2"
              />
              {t('buttons.back')}
            </Button>
          </div>
        </Box>
      )}
    </div>
  );
};

export default UpdatedUserProfileForm;
