import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { onLogin } from 'services/auth';
import { useAppContext } from '@containers';

const Login = ({
  onSignUp = () => {},
  onRecover = () => {},
  onClose = () => {},
}) => {
  const { setLogin } = useAppContext();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation('common');
  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // ServicesService;
    const { data, error } = await onLogin(form);
    if (data?.success) {
      const { data: res } = data;
      setLogin({
        ...res,
      });
    }
    onClose();
  };
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      id="form_login"
      sx={{
        '& .MuiInputBase-root, .MuiInputLabel-root': {
          color: '#fff !important',
        },
      }}
    >
      <TextField
        className="my-1"
        fullWidth
        label="Email"
        margin="dense"
        name="email"
        required
        size={'small'}
        type="email"
        value={form?.email}
        variant="standard"
        onChange={(res) => {
          setForm({
            ...form,
            email: res.target.value,
          });
        }}
      />

      <TextField
        className="my-1"
        fullWidth
        label="Password"
        margin="dense"
        name="password"
        required
        size={'small'}
        type={!showPassword ? 'password' : 'text'}
        value={form?.password}
        variant="standard"
        onChange={(res) => {
          setForm({
            ...form,
            password: res.target.value,
          });
        }}
        InputProps={{
          endAdornment: (
            <>
              {form?.password?.length >= 1 && (
                <IconButton
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff color="primary" />
                  ) : (
                    <Visibility color="primary" />
                  )}
                </IconButton>
              )}
            </>
          ),
        }}
      />
      <div className="space-y-1 mt-6">
        <Button
          color="primary"
          form={'form_login'}
          fullWidth
          onSubmit={onSubmit}
          type="submit"
          variant="contained"
        >
          {t('login.sign_in')}
        </Button>

        <Button
          color="secondary"
          fullWidth
          onClick={onRecover}
          variant="text"
        >
          <span className="normal-case font-normal">
            {t('login.forgot_password')}?
          </span>
        </Button>
        <span
          className={classnames(
            'relative w-44 block lg:w-fit my-1',
            "after:absolute after:-right-16 after:bottom-1/2 after:block after:h-[0.5px] after:w-10 after:bg-primary-400 after:content-[''] after:lg:-right-14",
            "mx-auto before:absolute before:-left-16 before:bottom-1/2 before:block before:h-[0.5px] before:w-10 before:bg-primary-400 before:content-[''] before:lg:-right-14",
          )}
        >
          <span
            className={classnames(
              ' text-sm tracking-[0.28em]',
              'label ',
            )}
          >
            {t('general.or')}
          </span>
        </span>
        <Button
          color="primary"
          fullWidth
          onClick={onSignUp}
          variant="contained"
        >
          {t('login.sign_up')}
        </Button>
      </div>
    </Box>
  );
};

export default Login;
