import { parseFormData } from '@utils';
import axios from 'axios';
import { omit } from 'lodash';
const baseUrl = process.env.NEXT_PUBLIC_ADITUS_API;

const prepareHeaders = (access_token) => {
  const headers = {};
  const token = access_token;
  if (token) {
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export const onGetCart = async (
  { locale = 'it' },
  access_token,
) => {
  const path = `${baseUrl}/v3/cart_products?lang=${locale}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = {
          ...response,
          data: {
            products: res?.data?.data || [],
            created_at: res?.data?.created_at || null,
            ...omit(res.data, 'data'),
          },
        };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getCart', error);
    return { error };
  }
  return response;
};

export const getCartCreationDate = async (access_token) => {
  const path = `${baseUrl}/carts`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getCartCreationDate', error);
    return { error };
  }
  return response;
};

export const onSetCart = async (cart, access_token) => {
  const path = `${baseUrl}/v3/cart_products`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    const formData = new FormData();
    formData.append('products', JSON.stringify(cart.products));
    await axios
      .post(path, formData, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('setCart', error);
    return { error };
  }
  return response;
};

export const setCardHolders = async ({ data }, access_token) => {
  const path = `${baseUrl}/v3/carts/product-holders`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    const formData = new FormData();
    formData.append('holders', JSON.stringify(data));
    await axios
      .post(path, formData, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('setCardHolders', error);
    return { error };
  }
  return response;
};

export const deleteCartItem = async ({ id }, access_token) => {
  const path = `${baseUrl}/v3/cart_products/${id}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .delete(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('deleteCartItem', error);
    return { error };
  }
  return response;
};

export const deleteCartItems = async ({ ids }, access_token) => {
  const path = `${baseUrl}/v3/cart_products?ids=[${ids.toString()}]`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .delete(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('deleteCartItems', error);
    return { error };
  }
  return response;
};
