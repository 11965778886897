import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = (e) => {
      setSourceLoaded(src);
    };
  }, [src]);

  return sourceLoaded;
};

export const useMediaQueries = () => {
  // Breakpoints
  const t = useTheme();
  const isMobile = useMediaQuery(() => t.breakpoints.down('md'));
  const isTablet = useMediaQuery(() =>
    t.breakpoints.between('md', 'lg'),
  );
  const isDesktop = useMediaQuery(() => t.breakpoints.up('lg'));
  const isLargeScreen = useMediaQuery('(min-width:1600px)');

  return { isMobile, isTablet, isDesktop, isLargeScreen };
};

export const useStrapiImageFormat = ({
  img,
  placeholder = 'https://images.unsplash.com/photo-1617079114138-9cf245e006c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1600&q=60',
}) => {
  const { isMobile, isTablet, isDesktop } = useMediaQueries();



  if (img && typeof img === 'object') {
    // Formats
    const { formats, url } = img?.data?.attributes || img;
    const { small, medium, large } = formats || {};
  
    if (!formats || !url) return placeholder;

    if (isMobile) return medium?.url;
    if (isTablet) return medium?.url || large?.url;
    if (isDesktop) return url;

   

    return url;
  }

  return 'https://images.unsplash.com/photo-1617079114138-9cf245e006c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1600&q=60';
};
