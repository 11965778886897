import { Cta, Download, Header, Section } from '@components';

export default function Cuisine({
  title,
  label,
  description,
  watermark,
  cta = null,
  className,
}) {
  return <Section fullWidth className={className}>
    <div className='container p-4 mx-auto text-center space-y-14'>
      <Header
        light
        centered
        fullWidth
        label={label}
        title={title}
        watermark={watermark}
        description={description}
      />

      {cta?.label && cta?.file && (
        <>
          <Download
            href={cta?.file?.data?.attributes?.url}
            label={cta?.label}
            className='max-w-xs mx-auto'
          />
        </>
      )}
    </div>
  </Section>
}
