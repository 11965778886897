import { FouRoomsLogo, MomentoLogo } from '@icons';
import { SwitchLocale } from '@components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import chunk from 'lodash/chunk';
import classnames from 'classnames';
import { links } from '@mock';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useMediaQueries } from '@hooks';

export default function Navbar() {
  // Next hooks
  const { pathname, locale } = useRouter();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { isDesktop } = useMediaQueries();

  const isFouRooms = pathname === '/4rooms';
  const [firstHalfLinks, secondHalfLinks, ...othersLink] = chunk(
    links,
    2,
  );

  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const linksCls = classnames(
    'relative w-auto min-w-fit',
    'tracking-airy-light text-sm font-voice',
    'font-light uppercase text-cream-50 md:text-base',
    'transition-color duration-500 ease-in-out',
    "before:content-[''] before:absolute before:block before:w-full before:h-[2px]",
    'before:-bottom-1 before:-left-0.5 before:bg-primary-500',
    'before:rounded-md',
    'before:hover:scale-x-100 before:scale-x-0 before:origin-top-left',
    'before:transition before:ease-in-out before:duration-300',
  );

  const linksList = classnames(
    'items-center hidden xl:flex',
    'space-x-2 xl:space-x-6',
  );

  return (
    <>
      <nav
        className={classnames(
          'flex h-[80px] flex-col items-center',
          'w-auto select-none overflow-hidden',
          'absolute left-0 top-0 z-40 w-screen',
        )}
      >
        {links?.length > 0 &&
          firstHalfLinks?.length > 0 &&
          secondHalfLinks?.length > 0 && (
            <div
              className={classnames(
                'flex items-center justify-between',
                'md:justify-center',
                'mx-auto md:space-x-12 lg:space-x-14',
              )}
            >
              <ul className={linksList}>
                {firstHalfLinks?.map(({ path, label }, i) => {
                  return (
                    <li className="flex w-32" key={i}>
                      <Link
                        key={path}
                        href={path}
                        className={linksCls}
                      >
                        {label[locale]}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <Link href="/">
                {isFouRooms ? (
                  <FouRoomsLogo
                    className={classnames(
                      'h-20 w-40 fill-cream-50',
                    )}
                  />
                ) : (
                  <MomentoLogo
                    className={classnames(
                      'h-20 w-40 fill-cream-50',
                    )}
                  />
                )}
              </Link>

              <ul className={linksList}>
                {secondHalfLinks?.map(({ path, label }, y) => {
                  return (
                    <li className="flex w-32" key={path}>
                      <Link href={path} className={linksCls}>
                        {label[locale]}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

        <IconButton
          onClick={onOpenDrawer}
          className="absolute z-50 flex right-4 top-5 md:right-8 md:top-8 xl:hidden"
        >
          <MenuIcon className="fill-white" />
        </IconButton>
      </nav>

      {!isDesktop && (
        <SwipeableDrawer
          anchor="right"
          open={openDrawer}
          onOpen={() => {}}
          onClose={onCloseDrawer}
        >
          <div className="relative w-screen h-screen bg-cover border-b-2 centered border-primary-500">
            <MomentoLogo className="absolute w-40 top-10 fill-cream-50" />

            <IconButton
              onClick={onCloseDrawer}
              className="absolute flex right-6 top-5"
            >
              <CloseIcon className="fill-white" />
            </IconButton>

            <ul className="flex flex-col items-end space-y-8">
              {links?.map(({ path, label }, i) => {
                return (
                  <li className="flex justify-center w-32" key={i}>
                    <Link
                      onClick={onCloseDrawer}
                      key={path}
                      href={path}
                      className={classnames(
                        'relative w-auto min-w-fit',
                        'font-voice text-xl tracking-airy-light',
                        'font-light uppercase text-cream-50 ',
                      )}
                    >
                      {label[locale]}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <SwitchLocale className="absolute centered bottom-20" />
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
}
