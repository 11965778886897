import {
  Login_Form,
  Recover_Form,
  Register_Form,
} from 'components/Forms';
import {
  AccountCircle,
  Close,
  Logout,
  Person,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '@containers';
import { useRouter } from 'next/router';
import { onLogout } from '@services';
const Login = ({ className }) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { user, setLogout, setReconciliation, onShowLoginDialog } =
    useAppContext();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('sign_in');

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickProfileIcon = () => push('/user/');
  const onClickLogout = async (e) => {
    try {
      await onLogout();
      setLogout();
      setReconciliation(false);
      push('/');
    } catch (error) {
      console.error('onClickLogout', error);
    }
  };

  const handleLogin = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
    onShowLoginDialog(false);
  };
  useEffect(() => {
    if (!open) {
      const timeoutId = setTimeout(() => {
        setType('sign_in');
      }, [1000]);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [open]);

  useEffect(() => {
    if (user?.showLoginDialog) {
      setOpen(true);
      setType('sign_in');
    }
  }, [user?.showLoginDialog]);

  const formByType = () => {
    switch (type) {
      case 'sign_in':
        return (
          <Login_Form
            onSignUp={() => setType('sign_up')}
            onRecover={() => setType('forgot_password')}
            onClose={handleClose}
          />
        );
      case 'sign_up':
        return <Register_Form onClose={handleClose} />;
      case 'forgot_password':
        return <Recover_Form onClose={handleClose} />;
      default:
        break;
    }
  };

  return (
    <>
      <div className={classNames(className, 'w-fit')}>
        {user?.logged ? (
          <>
            <Tooltip title={t('pages.personal_area.title')}>
              <IconButton onClick={handleClick}>
                <AccountCircle htmlColor="#fff" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={t('login.sign_in')}>
              <IconButton onClick={handleLogin}>
                <Person htmlColor="#fff" fontSize="small" />{' '}
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
      <Menu
        open={openMenu}
        id="locale-menu"
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'locale-button',
        }}
      >
        <MenuItem
          className="md:space-x-1"
          onClick={() => {
            onClickProfileIcon();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <Person className="text-primary-400" />
          </ListItemIcon>
          <span className="font-bilo text-base">
            Area personale
          </span>
        </MenuItem>

        <MenuItem
          className="md:space-x-1"
          onClick={() => {
            // dispatch(setReductionsIDsReducer([]));
            onClickLogout();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <Logout className="text-primary-400" />
          </ListItemIcon>
          <span className="font-bilo text-base">Log out</span>
        </MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
        fullWidth
      >
        <div className="text-center p-4">
          <span className="label font-semibold">
            {t(`login.${type}`)}
          </span>
          <IconButton
            className="absolute top-[10px] right-3"
            onClick={handleClose}
          >
            <Close className="text-primary-400" fontSize="small" />
          </IconButton>
        </div>
        <DialogContent>{formByType()}</DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
