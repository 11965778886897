import { useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export default function Calendar({
  date,
  onChangeDate,
  minDate = new Date(),
}) {
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        openTo="day"
        value={date}
        views={['day']}
        showToolbar={false}
        minDate={minDate}
        disablePast={true}
        renderInput={() => null}
        onChange={onChangeDate}
        displayStaticWrapperAs="desktop"
        className="bg-transparent custom-calendar"
        onError={(e) =>
          console.error('StaticDatePicker', e?.message)
        }
        sx={{
          '& .Muipickerslayout-root': {},
          '& .MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize !important',
            paddingLeft: 2,
          },
          '& .MuiDateCalendar-root': {
            // boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            minWidth: '354px  !important',
            // borderRadius: '8px',
            // backgroundColor: 'rgba(255, 255, 255, 0.15)',
            width: '100%  !important',
            [theme.breakpoints.down('md')]: {
              minWidth: 'auto !important',
            },
          },
          '& .MuiDayCalendar-weekDayLabel': {
            width: '100%  !important',
          },
          '& .MuiDayCalendar-weekContainer': {
            justifyContent: 'space-around !important',
          },
          '& .Mui-disabled:not(.Mui-selected)': {
            opacity: 0.5,
          },
        }}
      />
    </LocalizationProvider>
  );
}
