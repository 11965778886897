import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Chip } from '@mui/material';
const Schedules = ({ data }) => {
  const { schedules, header } = data;
  return (
    <div className="bg-secondary-800/10 p-8   shadow-card rounded-xl flex flex-col items-start space-y-6">
      <Chip
        variant="outlined"
        label={header?.title}
        className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
        sx={{
          '& .MuiChip-label': {
            padding: '16px 32px !important',
          },
        }}
      />
      <div>{header?.description}</div>
      <div className="space-y-6">
        {schedules.map((elem) => (
          <div
            key={elem?.id}
            className="border-l-2 pl-6 flex items-center"
          >
            <div className="w-16 min-w-16 flex flex-col text-primary-400 justify-center">
              <span>{elem?.start}</span>
              <span>{elem?.end}</span>
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <span className="semibold">{elem?.title}</span>
              <span>{elem?.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedules;
