import {
  Cart,
  Cta,
  Footer,
  Login,
  Navbar,
  SwitchLocale,
  AuthWall,
} from '@components';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useAppContext } from '@containers';

function DefaultLayout({
  children,
  header,
  className,
  showBookingCta = true,
  cta = null,
  enableAuthWall = false,
}) {
  const { locale, push } = useRouter();
  const { user } = useAppContext();
  return (
    <>
      <div
        id="default-layout"
        className={classnames(
          'layout default-layout',
          'min-h-screen',
          'relative overflow-x-hidden',
        )}
      >
        <div className="absolute z-50  left-8 top-8 flex items-center">
          <SwitchLocale className=" hidden  md:flex" />
          <Login />
          <Cart />
        </div>

        {showBookingCta && (
          <div className="fixed right-0 top-1 z-[99] hidden h-40 w-40 justify-end xl:flex">
            <Cta
              label={locale === 'it' ? 'Prenota' : 'Reservations'}
              className="w-full right-8 top-5"
              href="/booking"
            />
          </div>
        )}
        {cta && cta}
        <Navbar />

        {header && header}
        <main
          className={classnames(
            className,
            'relative z-50 mb-[45rem] bg-black xl:mb-[40rem]',
          )}
        >
          {!enableAuthWall && children}
          {enableAuthWall && user?.logged && children}
          {enableAuthWall && !user?.logged && <AuthWall />}
          {showBookingCta && (
            <Box className={classnames('fixed bottom-8 right-8')}>
              <Fab
                color="primary"
                className={classnames(
                  'group z-50 block rounded-full',
                  'bg-primary-500 md:hidden',
                  'hover:bg-cream-50 hover:text-primary-500',
                )}
              >
                <RestaurantRoundedIcon
                  className="group:hover:text-primary-500"
                  fontSize="small"
                  onClick={() => push('/booking')}
                />
              </Fab>
            </Box>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
}

export default DefaultLayout;
