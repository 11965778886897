import { Header, Section } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Link from 'next/link';
import StarIcon from '@mui/icons-material/Star';
import classnames from 'classnames';

export default function Reviews({
  list,
  title,
  label,
  description,
  img,
}) {
  return list?.length > 0 ? (
    <Section
      id="reviews"
      fullWidth
      className={classnames(
        'centered relative flex h-130 w-screen flex-col',
      )}
    >
      <div className={classnames('flex flex-col space-y-8')}>
        <Header
          label={label}
          title={title}
          description={description}
          centered
          fullWidth
        />

        {list?.length > 0 && (
          <Splide
            tag="section"
            className={classnames('container mx-auto w-full')}
            options={{
              drag: true,
              type: 'slide',
              width: '100vw',
              height: '100%',
              autoplay: true,
              pagination: true,
              arrows: false,
              padding: 0,
              rewind: true,
              snap: true,
              perPage: 1,
              perMove: 1,
              focus: 0,
              arrows: false,
              flickPower: 800,
              flickMaxPages: 1,
              lazyLoad: 'nearby',
              video: {
                loop: true,
                mute: true,
              },
            }}
          >
            <div className={classnames('splide__progress')}>
              <div
                className={classnames(
                  'splide__progress__bar',
                  'absolute',
                  'top-0',
                  'z-[999]',
                  'h-[1px]',
                  'bg-primary-600',
                )}
              />
            </div>

            {list?.map(({ customer, text, stars, store }, i) => {
              return (
                <SplideSlide
                  key={i}
                  className={classnames(
                    'relative mx-auto flex justify-center pb-8',
                  )}
                >
                  <div
                    className={classnames(
                      'relative h-auto space-y-6',
                    )}
                  >
                    <p
                      className={classnames(
                        'paragraph relative text-center',
                      )}
                    >
                      <FormatQuoteIcon
                        className={classnames('mx-2')}
                      />
                      {text}
                      <FormatQuoteIcon
                        className={classnames('mx-2')}
                      />
                    </p>

                    {stars > 0 && (
                      <div
                        className={classnames(
                          'mx-auto flex justify-center space-x-4',
                        )}
                      >
                        {new Array(stars).fill(0).map((s, y) => (
                          <StarIcon
                            key={y}
                            className="fill-yellow-200"
                          />
                        ))}

                        {new Array(5 - stars)
                          .fill(0)
                          .map((s, y) => (
                            <StarIcon key={y} />
                          ))}
                      </div>
                    )}

                    <span
                      className={classnames(
                        'flex justify-center',
                        'label mx-auto mt-12 w-full',
                        'text-center',
                      )}
                    >
                      {customer}
                    </span>

                    {store?.data?.attributes && (
                      <Link
                        className={classnames(
                          'mx-auto w-fit',
                          'relative flex items-center justify-center text-sm tracking-wider text-cream-50 md:text-lg',
                          "before:absolute before:block before:h-[2px] before:w-full before:content-['']",
                          'before:-bottom-1 before:-left-0.5 before:bg-primary-500',
                          'before:rounded-md',
                          'before:origin-top-left before:scale-x-0 before:hover:scale-x-100',
                          'before:transition before:duration-300 before:ease-in-out',
                        )}
                        href={store?.data?.attributes?.slug}
                      >
                        {store?.data?.attributes?.name}
                      </Link>
                    )}
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        )}
      </div>
      <div className={classnames('dark-layer')} />
      <div
        className={classnames(
          'absolute bottom-0',
          'w-screen',
          '-inset-y-[50px] inset-x-0 -z-[1]',
          'bg-gradient-to-b',
          'from-[#282828]/5 via-[#282828]/80 to-[#282828]',
        )}
      />

      <LazyLoadComponent>
        <div
          style={{
            backgroundImage: `url(${img?.data?.attributes?.url})`,
          }}
          className={classnames(
            'absolute',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            'bg-center',
            'bg-no-repeat',
            'bg-cover',
            'xl:bg-fixed',
            '-z-20',
          )}
        />
      </LazyLoadComponent>
    </Section>
  ) : null;
}
