import {
  Cta,
  Header,
  Section,
  LazyImage,
  BookingFoodDialog,
} from '@components';
import classnames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import { useStrapiImageFormat } from '@hooks';
import { useState } from 'react';
import Image from 'next/image';

export default function Tastings({ tastings = [], store }) {
  const [titleDialog, setTitleDialog] = useState('');
  const [showBookingDialog, setShowBookingDialog] = useState(false);

  if (tastings && tastings?.length > 0) {
    return (
      <>
        <Section
          cleared={true}
          fullWidth
          noPadding={true}
          //className="pt-16 pb-16"
        >
          <Header
            centered
            fullWidth
            title={'Le Nostre Degustazioni'}
            label={'Ti aspettiamo'}
          />

          <section
            className={classnames(
              'container relative z-20 mx-auto',
              'py-24',
              'grid-col-1 lg:grid-cols-2 xl:grid-cols-3',
              'grid gap-6 lg:gap-8 2xl:gap-10',
            )}
          >
            {tastings?.map(({ attributes }, i) => {
              const { name, abstract, includes, hero, tag } =
                attributes;

              return (
                <div
                  key={i}
                  className={classnames(
                    'relative z-0',
                    'mx-auto overflow-hidden',
                    'rounded-md bg-black',
                    'h-110 w-full md:h-120',
                  )}
                >
                  <div className="relative w-full h-48 overflow-hidden group mix-blend-plus-lighter lg:h-72">
                    <div className="relative z-10 p-8">
                      <span className="label !text-xs">{tag}</span>
                      <h3 className="max-w-xs my-2 text-2xl font-secondary">
                        {name}
                      </h3>
                      {abstract && (
                        <p className="hidden font-light opacity-70 2xl:flex">
                          {abstract}
                        </p>
                      )}
                    </div>
                    <Image
                      src={useStrapiImageFormat({
                        img: attributes?.img?.data?.attributes,
                        placeholder:
                          'https://images.unsplash.com/photo-1447279506476-3faec8071eee?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                      })}
                      fill
                      //layout='fill'
                      skeletonCls="w-full h-full absolute left-0 top-0"
                      className={classnames(
                        'absolute left-0 top-0',
                        '-z-0 scale-125 opacity-20',
                        'transition-all duration-500 ease-in-out',
                        'group-hover:saturate-150',
                        '-z-5 rounded-sm object-cover',
                        'group-hover:scale-150',
                        '',
                      )}
                    />
                  </div>

                  <div className="px-6 py-4 space-y-2 xl:px-8">
                    <div className="space-y-4">
                      <span>{includes?.description}</span>
                      <div className="space-y-1.5">
                        {includes?.list?.length > 0 &&
                          includes.list.map((l, i) => (
                            <div
                              className="flex flex-row space-x-4"
                              key={i}
                            >
                              <div>
                                <CheckIcon
                                  className="w-6 fill-green-600"
                                  fontSize="small"
                                />
                              </div>
                              <div>
                                <span className="font-normal uppecase text-xxs dark:text-white">
                                  {l?.name}
                                </span>
                                <p>{l?.text}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="absolute bottom-0 left-0 w-full">
                    <Cta
                      label={hero?.cta}
                      onClick={() => {
                        setShowBookingDialog(true);
                        setTitleDialog(hero?.cta);
                      }}
                      className="w-full rounded-t-none"
                    />
                  </div>
                </div>
              );
            })}
          </section>

          <div
            className={classnames(
              'absolute top-0',
              'w-screen',
              'inset-x-0 inset-y-[50px] -z-[0]',
              'bg-gradient-to-t',
              'from-[#282828]/5 via-[#282828]/50 to-black',
            )}
          />
        </Section>

        <BookingFoodDialog
          open={showBookingDialog}
          setShowFoodDialog={setShowBookingDialog}
          title={titleDialog}
          phone={store?.phone}
        />
      </>
    );
  }

  return null;
}
