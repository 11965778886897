import { Box, Button, TextField } from '@mui/material';
import { onRecoverPassword } from '@services';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recover = ({ onClose = () => {} }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [form, setForm] = useState({
    email: '',
    language_code: locale,
  });
  const onForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCaptcha(true);
  };
  const onSubmit = async (payload) => {
    try {
      await onRecoverPassword(payload);
    } catch (error) {
      console.error(error);
    }
    onClose();
  };
  return (
    <>
      {showCaptcha && (
        <div className="flex justify-center">
          <ReCAPTCHA
            hl={locale}
            className="my-6 w-full bg-transparent first:w-fit"
            onChange={async (e) => {
              onSubmit({
                ...form,
                'g-recaptcha-response': e,
                domain: window.location.hostname,
              });
            }}
            sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA}
          />
        </div>
      )}

      {!showCaptcha && (
        <Box
          id="form_recover"
          component={'form'}
          onSubmit={onForm}
          sx={{
            '& .MuiInputBase-root, .MuiInputLabel-root': {
              color: '#fff !important',
            },
          }}
        >
          <TextField
            className="my-1 mb-3"
            fullWidth
            label="Email"
            margin="dense"
            name="email"
            required
            type="email"
            size={'small'}
            value={form?.email}
            variant="standard"
            onChange={(res) => {
              setForm({
                ...form,
                email: res.target.value,
              });
            }}
          />
          <Button
            color="primary"
            form="form_recover"
            fullWidth
            onSubmit={onForm}
            type="submit"
            variant="contained"
          >
            {t('forms.recover_password')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Recover;
