import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateField,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { getLangLabel } from '@utils';
import { useMediaQueries } from '@hooks';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

const Stepper_C = ({
  onSubmit = () => {},
  store,
  type,
  stores,
}) => {
  const { locale } = useRouter();
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation();

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [formValues, setFormValues] = useState({
    name: null,
    surname: null,
    email: null,
    phone: '',
    date: null,
    time: null,
    store: store || '',
    city: null,
    location: null,
    type: type,
    number: null,
    message: null,
    lang: locale,
  });
  const [activeStep, setActiveStep] = useState(0);
  const inputCls = classnames(
    'flex flex-col text-xl !tracking-widest',
    '!border-none !shadow-none !outline-none',
    '!bg-gray-200 text-black p-0 m-0',
  );
  const sx = {
    '& .MuiInputBase-root, .MuiInputLabel-root': {
      color: '#000 !important',
    },
  };
  const fieldSize = isMobile ? 'small' : 'medium';

  const steps = [
    {
      id: 'dateForm',
      label: t('forms.select_date_place'),
      isOptional: false,
      component: (
        <Box
          component={'form'}
          id="dateForm"
          onSubmit={(e) => handleNext(e)}
        >
          {/* GIORNO + ORA */}
          <div className="flex flex-col w-full space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <FormControl
              className="space-y-4"
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Giorno', 'Day'])}
              </span>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  disablePast={true}
                  color="secondary"
                  className={inputCls}
                  format="DD-MM-YYYY"
                  value={formValues?.date}
                  required
                  onChange={(val) => {
                    return setFormValues({
                      ...formValues,
                      date: dayjs(val),
                    });
                  }}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl
              className="space-y-4 "
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Orario', 'Hour'])}
              </span>

              <Autocomplete
                size={fieldSize}
                className={inputCls}
                disablePortal
                color="secondary"
                placeholder="Slot"
                id="combo-box-demo"
                onChange={(val) => {
                  console.log(
                    val?.target?.value || val?.target?.innerHTML,
                  );

                  return setFormValues({
                    ...formValues,
                    time:
                      val?.target?.value || val?.target?.innerHTML,
                  });
                }}
                options={[
                  '08:30',
                  '09:00',
                  '09:30',
                  '10:00',
                  '10:30',
                  '11:00',
                  '11:30',
                  '12:00',
                  '12:30',
                  '13:00',
                  '13:30',
                  '14:00',
                  '14:30',
                  '15:00',
                  '15:30',
                  '16:00',
                  '16:30',
                  '17:00',
                  '17:30',
                  '18:00',
                  '18:30',
                  '19:00',
                  '19:30',
                  '20:00',
                  '20:30',
                  '21:00',
                  '21:30',
                  '22:00',
                ].map((h, i) => ({ label: h, id: i }))}
                renderInput={(params) => (
                  <TextField
                    required
                    color="secondary"
                    {...params}
                    size={fieldSize}
                    className={inputCls}
                  />
                )}
              />
            </FormControl>
          </div>
          {/* STORE */}
          <FormControl
            className="w-full mx-auto my-8 space-y-4"
            size={fieldSize}
            sx={sx}
          >
            <span className={classnames('label lg:mx-auto')}>
              {getLangLabel(locale, ['Dove', 'Where'])}
            </span>

            <Select
              fullWidth
              color="secondary"
              size={fieldSize}
              required
              value={formValues?.store}
              onChange={(val) => {
                const selectedStore = stores?.find(
                  ({ attributes }) =>
                    attributes?.slug === val?.target?.value,
                );

                if (selectedStore) {
                  const { slug, city, location } =
                    selectedStore?.attributes;

                  return setFormValues({
                    ...formValues,
                    store: slug,
                    city: city?.data?.attributes?.name,
                    location: location?.data?.attributes?.name,
                  });
                }
              }}
              defaultValue={
                stores?.find(
                  ({ attributes }) => attributes?.slug === store,
                )?.slug
              }
              sx={{
                boxShadow: 'none',
                '&:active': {
                  outline: 'none',
                },
                '&:focus': {
                  outline: 'none',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '.MuiSelect-select': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
                '.MuiInputBase-root': {
                  '.css-1x0j9mc-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    { border: 'none !important;' },
                },
              }}
              className={classnames(
                'flex flex-col text-xl tracking-widest',
                ' border-none shadow-none outline-none',
                'bg-gray-200 fill-white text-black',
              )}
            >
              {stores?.map(({ attributes }, i) => {
                const { name, slug, city, location } = attributes;

                return (
                  <MenuItem
                    // variant="outlined"
                    color="secondary"
                    key={i}
                    value={slug}
                    className="flex flex-col items-center py-6 text-xl tracking-widest"
                  >
                    <span className="text-xs italic text-primary-500">
                      {city?.data?.attributes?.name}
                    </span>
                    <span className="font-medium">{name}</span>
                    <span className="text-xs">
                      {location?.data?.attributes?.name}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* TIPOLOGIA */}
          <FormControl
            className="w-full mx-auto mb-8 space-y-4"
            size={fieldSize}
            sx={sx}
            required
          >
            <span className={classnames('label mx-auto')}>
              {getLangLabel(locale, ['Tipologia', 'Type'])}
            </span>

            <ButtonGroup
              disableElevation
              variant="contained"
              className="space-x-2 lg:space-x-4"
              aria-label="Disabled elevation buttons"
            >
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  return setFormValues({
                    ...formValues,
                    type: 'table',
                  });
                }}
                className={classnames(
                  'h-12 rounded-none bg-gray-200 tracking-widest text-black',
                  'transition-colors duration-300 ease-in-out',
                  {
                    'bg-primary-500 text-white':
                      formValues?.type === 'table',
                  },
                )}
              >
                {getLangLabel(locale, ['Tavolo', 'Table'])}
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  return setFormValues({
                    ...formValues,
                    type: 'event',
                  });
                }}
                variant="contained"
                className={classnames(
                  'h-12 rounded-none bg-gray-200 tracking-widest text-black',
                  'transition-colors duration-300 ease-in-out',
                  {
                    'bg-primary-500 text-white':
                      formValues?.type === 'event',
                  },
                )}
              >
                {getLangLabel(locale, ['Evento', 'Event'])}
              </Button>
            </ButtonGroup>
          </FormControl>
        </Box>
      ),
    },
    {
      id: 'registryForm',
      label: t('forms.insert_personal_data'),
      isOptional: false,
      component: (
        <Box
          component={'form'}
          id={'registryForm'}
          onSubmit={(e) => handleNext(e)}
        >
          {/* NAME + SURNAME */}
          <div className="flex flex-col w-full space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <FormControl
              fullWidth
              size={fieldSize}
              className="space-y-4 bg-transparent"
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Nome', 'Name'])}
              </span>
              <TextField
                required
                fullWidth
                inputProps={{ maxLength: 80 }}
                // variant="outlined"
                color="secondary"
                placeholder="Name"
                className={inputCls}
                onChange={(val) => {
                  return setFormValues({
                    ...formValues,
                    name: val?.target?.value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              className="space-y-4 "
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Cognome', 'Surname'])}
              </span>
              <TextField
                required
                fullWidth
                inputProps={{ maxLength: 80 }}
                color="secondary"
                placeholder="Surname"
                className={inputCls}
                onChange={(val) => {
                  return setFormValues({
                    ...formValues,
                    surname: val?.target?.value,
                  });
                }}
              />
            </FormControl>
          </div>
          {/* EMAIL + PHONE */}
          <div className="flex flex-col w-full space-y-8 my-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <FormControl
              className="space-y-4"
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>Email</span>
              <TextField
                required
                fullWidth
                inputProps={{ maxLength: 120 }}
                color="secondary"
                className={inputCls}
                placeholder="your@email.it"
                onChange={(val) => {
                  return setFormValues({
                    ...formValues,
                    email: val?.target?.value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              className="space-y-4 "
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Telefono', 'Phone'])}
              </span>
              <TextField
                required
                fullWidth
                color="secondary"
                className={inputCls}
                placeholder={getLangLabel(locale, [
                  'almeno 10 cifre',
                  '10 digits minimum',
                ])}
                value={formValues?.phone}
                inputProps={{ pattern: '[0-9]*' }}
                onChange={(val) => {
                  const phone = val?.target?.value;

                  if (!phone?.length) {
                    setFormValues({
                      ...formValues,
                      phone: '',
                    });
                  }

                  if (/^\d+$/.test(phone)) {
                    setFormValues({
                      ...formValues,
                      phone,
                    });
                  }
                }}
              />
            </FormControl>
          </div>
          {/* PERSON */}
          <div className="flex flex-col w-full space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <FormControl
              className="space-y-4"
              size={fieldSize}
              fullWidth
              sx={sx}
            >
              <span className={classnames('label')}>
                {getLangLabel(locale, ['Persone', 'People'])}
              </span>
              <TextField
                required
                fullWidth
                type="number"
                inputProps={{
                  maxLength: 100,
                  min: 1,
                  max: 100,
                }}
                color="secondary"
                className={inputCls}
                placeholder="1"
                onChange={(val) => {
                  return setFormValues({
                    ...formValues,
                    number: val?.target?.value,
                  });
                }}
              />
            </FormControl>
          </div>
        </Box>
      ),
    },
    {
      id: 'messageForm',
      label: t('forms.leave_message'),
      isOptional: true,
      component: (
        <Box
          component={'form'}
          id={'messageForm'}
          onSubmit={(e) => onSubmit(e, formValues)}
        >
          {/* MESSAGE */}
          <FormControl
            className="w-full mx-auto my-8 space-y-4"
            size={fieldSize}
            fullWidth
            sx={sx}
          >
            <span className={classnames('label mx-auto')}>
              {getLangLabel(locale, ['Messaggio', 'Message'])}
            </span>

            <TextField
              inputProps={{ maxLength: 800 }}
              placeholder="Message"
              multiline
              fullWidth
              className={classnames(inputCls, 'text-black')}
              rows={4}
              onChange={(val) => {
                return setFormValues({
                  ...formValues,
                  message: val?.target?.value,
                });
              }}
            />
          </FormControl>
          <>
            {locale === 'it' && (
              <div className="font-light text-center text-cream-50">
                I suoi dati saranno trattati per gestire la
                prenotazione e/o per darvi le informazioni
                richieste. Letta
                <Link
                  href="/privacy-policy"
                  className="px-1 underline text-primary-400 underline-offset-2"
                >
                  informativa privacy
                </Link>
              </div>
            )}

            {locale === 'en' && (
              <div className="font-light text-center text-cream-50">
                Your data will be processed to manage the
                reservation and/or to give you the requested
                information. Read
                <Link
                  href="/privacy-policy"
                  className="px-1 underline text-primary-400 underline-offset-2"
                >
                  privacy disclaimer
                </Link>
              </div>
            )}
          </>
        </Box>
      ),
    },
  ];

  const handleNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (activeStep !== steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep(0);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const toCheck = Object.values(omit(formValues, 'message'));
    setEnableSubmit(
      toCheck?.every((x) => x && x) &&
        formValues?.phone?.length > 9,
    );
  }, [formValues]);
  useEffect(() => {
    if (store) {
      const selectedStore = stores?.find(
        ({ attributes }) => attributes?.slug === store,
      );

      if (selectedStore) {
        const { slug, city, location } = selectedStore?.attributes;

        setFormValues({
          ...formValues,
          store: slug,
          city: city?.data?.attributes?.name,
          location: location?.data?.attributes?.name,
        });
      }
    }
  }, []);
  return (
    <div className="md:max-w-3xl w-full mx-auto">
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const { label, isOptional } = step;
          const stepProps = {};
          const labelProps = {};
          if (isOptional && activeStep === index) {
            labelProps.optional = (
              <p className="text-xs">({t('forms.optional')})</p>
            );
          }

          return (
            <Step key={label} {...stepProps}>
              {
                <StepLabel {...labelProps}>
                  {activeStep === index ? label : ''}
                </StepLabel>
              }
            </Step>
          );
        })}
      </Stepper>
      <div className=" my-8 space-y-12 ">
        {steps[activeStep]?.component}
      </div>
      {activeStep === steps.length ? (
        <></>
      ) : (
        <div className="flex ">
          <Button
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
            fullWidth
          >
            {t('buttons.back')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onSubmit={(e) => {
              if (activeStep === steps.length - 1) {
                onSubmit(e, formValues);
              } else {
                handleNext(e);
              }
            }}
            type="submit"
            fullWidth
            form={steps[activeStep]?.id}
            disabled={
              activeStep === steps.length - 1 && !enableSubmit
            }
            sx={{
              backgroundColor: '#da5f06 !important',
              '&:hover': {
                backgroundColor: '#ae4c05 !important',
              },
            }}
          >
            {activeStep === steps.length - 1
              ? t('buttons.send')
              : t('buttons.next')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Stepper_C;
