import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Chip } from '@mui/material';
const Description = ({ text, chip = true, isCard = true }) => {
  return (
    <div
      className={
        isCard &&
        'bg-secondary-800/10 p-8   shadow-card rounded-xl flex flex-col items-start space-y-6'
      }
    >
      {chip && (
        <Chip
          variant="outlined"
          label={'description'}
          className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
          sx={{
            '& .MuiChip-label': {
              padding: '16px 32px !important',
            },
          }}
        />
      )}

      <span>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          // className={classnames('w-full')}
        >
          {text}
        </ReactMarkdown>
      </span>
    </div>
  );
};

export default Description;
