import {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import EuroIcon from '@mui/icons-material/Euro';
import QuantitySelector from './QuantitySelector';
// import SimpleBar from 'simplebar-react';
import isEqual from 'lodash/isEqual';

import { useTranslation } from 'next-i18next';
import { useAppContext } from '@containers';
import { onGetCart } from '@services';
import { useRouter } from 'next/router';

const Tickets = forwardRef(
  (
    {
      tickets,
      onChange,
      fullPrice,
      currentCart,
      defaultSlot = null,
      onLimitReachedCallback,
      isTicketsLoading,
      isTicketsSuccess,
      date,
      hour,
      resetInnerCart = null,
      showTotal = true,
      itemClassNames,
      className,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [cart_, setCart_] = useState([...currentCart]);
    const { user, cart: clientCart } = useAppContext();
    const { access_token = null } = user;
    const { locale } = useRouter();
    const [serverCart, setServerCart] = useState(null);
    const [isServerCartLoading, setIsServerCartLoading] =
      useState(false);
    const [isServerCartSuccess, setIsServerCartSuccess] =
      useState(false);
    const groupTicketsBy = (tickets) => {
      try {
        const groupByCard = tickets?.some(({ isCard }) =>
          Boolean(isCard),
        );

        const groupByLangs = tickets?.some(
          ({ name }) =>
            name.includes('(IT)') || name.includes('(EN)'),
        );

        const groupByPaths = tickets?.some(
          ({ product_subcategory }) => product_subcategory,
        );

        if (groupByCard) {
          return [
            {
              label: null,
              list: tickets.filter((t) => !Boolean(t?.isCard)),
            },
            {
              label: 'CARD',
              list: tickets.filter((t) => Boolean(t?.isCard)),
            },
          ];
        }
        if (groupByLangs) {
          return [
            {
              label: t(
                'drawers.book_product.steps.see_also.onlyIT',
              ),
              list: tickets.filter(({ name }) =>
                name.includes('(IT)'),
              ),
            },
            {
              label: t(
                'drawers.book_product.steps.see_also.onlyEN',
              ),
              list: tickets.filter(({ name }) =>
                name.includes('(EN)'),
              ),
            },
          ];
        }

        return [
          {
            label: null,
            list: tickets,
          },
        ];
      } catch (error) {
        console.error('groupTicketsBy', error);
        return [
          {
            label: '',
            list: tickets,
          },
        ];
      }
    };

    useEffect(() => {
      if (resetInnerCart !== null) setCart_([]);
    }, [resetInnerCart]);
    // Fetch cart data
    useEffect(() => {
      const fetchCartData = async () => {
        if (user?.logged) {
          setIsServerCartLoading(true);
          try {
            const data = await onGetCart(
              {
                locale,
              },
              access_token,
            );
            setServerCart(data);
            setIsServerCartSuccess(true);
          } catch (error) {
            console.error('Failed to fetch cart data', error);
            setIsServerCartSuccess(false);
          } finally {
            setIsServerCartLoading(false);
          }
        }
      };

      fetchCartData();
    }, [user]);
    useImperativeHandle(ref, () => ({
      resetState: () => {
        setCart_([]);
      },
    }));
    // Update cart
    useEffect(() => {
      if (!isEqual(currentCart, cart_)) {
        onChange(cart_);
      }
    }, [cart_, currentCart]);

    return (
      <>
        {/* Show loader during user cart get in order to merge current order to previous one */}
        {(isTicketsLoading || isServerCartLoading) && (
          <div
            className={classNames(
              'absolute left-1/2 top-1/2',
              '-translate-x-1/2 -translate-y-1/2 transform',
              // 'bg-zinc-100 dark:bg-zinc-800',
              'w-11/12 z-[999]',
              'flex items-center justify-center',
            )}
          >
            <CircularProgress size={20} />
          </div>
        )}

        {!isTicketsLoading &&
          isTicketsSuccess &&
          tickets?.length > 0 && (
            <div
              style={{
                maxHeight: '70vh',
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
                margin: 0,
              }}
            >
              {groupTicketsBy(tickets)?.map(
                ({ label, list }, i) => {
                  if (list?.length > 0) {
                    return (
                      <div
                        key={i}
                        className={classNames(
                          className,
                          'block pt-2 mb-8 pr-6',
                          {
                            'pb-12':
                              groupTicketsBy(tickets)?.length ===
                              i + 1,
                          },
                        )}
                      >
                        {label && (
                          <span className="block pb-4 text-sm text-primary">
                            {label}
                          </span>
                        )}
                        <ul
                          className={classNames(
                            'flex h-full w-full flex-col',
                            'text-white',
                            'space-y-3',
                          )}
                        >
                          {list?.map(
                            (
                              {
                                id,
                                name,
                                price_sale,
                                document_required,
                                service,
                                site_slot_availability,
                                product_subcategory,
                                min_pax,
                              },
                              i,
                            ) => {
                              const { site } = service;
                              let currentProduct = currentCart.find(
                                (c) => c.id === id,
                              );
                              // Check current quantity
                              const hasQuantity = currentCart.find(
                                (t) => t.id === id,
                              )?.qty;
                              // Client Cart
                              const clientQuantity =
                                clientCart?.products.filter(
                                  ({ product }) =>
                                    product?.id === id,
                                );
                              // Server Cart
                              const serverQuantity =
                                isServerCartSuccess
                                  ? serverCart?.products?.filter(
                                      ({ product }) =>
                                        product?.id === id,
                                    )
                                  : [];

                              const tot =
                                clientQuantity.length +
                                (hasQuantity || 0) +
                                (serverQuantity?.length || 0);

                              const limit =
                                service?.slots === false
                                  ? service?.category === 'service'
                                    ? 20
                                    : 6
                                  : service?.slot_availability;

                              return (
                                <li
                                  key={id}
                                  className={classNames(
                                    itemClassNames,
                                    'flex flex-col md:flex-row',
                                    'md:items-center md:justify-between',
                                  )}
                                >
                                  <div
                                    className={classNames(
                                      'flex items-center',
                                      'md:justify-start',
                                      'md:items-center',
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        'px-4 text-lg font-medium',
                                        'w-18 2xl:font-semibold',
                                      )}
                                    >
                                      {price_sale}€
                                    </span>

                                    <span
                                      className={classNames(
                                        'flex flex-col',
                                      )}
                                    >
                                      {product_subcategory && (
                                        <span
                                          className={classNames(
                                            'pt-1 font-semibold',
                                            'uppercase text-sm',
                                            'font-medium dark:text-primary',
                                          )}
                                        >
                                          {product_subcategory}
                                        </span>
                                      )}
                                      <span
                                        className={classNames({
                                          'uppercase text-sm font-semibold':
                                            !product_subcategory,
                                          'text-xs':
                                            product_subcategory,
                                        })}
                                      >
                                        {name
                                          .replace('(IT)', '')
                                          .replace('(EN)', '')}
                                      </span>
                                    </span>
                                  </div>

                                  <div className="flex flex-col md:justify-end">
                                    <QuantitySelector
                                      key={id}
                                      date={date}
                                      hour={hour}
                                      minPax={min_pax}
                                      currentProduct={
                                        currentProduct
                                      }
                                      alert={Boolean(
                                        serverQuantity?.length +
                                          clientQuantity?.length,
                                      )}
                                      minAllowed={
                                        user?.logged
                                          ? serverQuantity?.length
                                          : clientQuantity?.length
                                      }
                                      quantity={tot || 0}
                                      onClickSlot={(slot) => {
                                        if (currentProduct) {
                                          currentProduct = {
                                            ...currentProduct,
                                            hour_service: slot,
                                          };
                                          setCart_([
                                            currentProduct,
                                            ...currentCart.filter(
                                              (c) => c.id !== id,
                                            ),
                                          ]);
                                        }
                                      }}
                                      slots={service?.slots}
                                      limitReached={tot === limit}
                                      onAddCallback={() => {
                                        if (tot < limit) {
                                          if (currentProduct) {
                                            let { qty } =
                                              currentProduct;
                                            currentProduct = {
                                              ...currentProduct,
                                              qty: ++qty,

                                              ...(defaultSlot
                                                ? {
                                                    hour_service:
                                                      defaultSlot,
                                                  }
                                                : {}),
                                            };
                                            setCart_([
                                              currentProduct,
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                          } else {
                                            setCart_([
                                              {
                                                id,
                                                name,
                                                qty: 1,
                                                price_sale,
                                                document_required,
                                                // Check if site is in concession: this value is important to compose link into cart list
                                                in_concession: site
                                                  ? Boolean(
                                                      site?.in_concession,
                                                    )
                                                  : true,
                                                // Same for the previous comment: this value is used to compose right path in case of site without concession
                                                product_category:
                                                  service?.category,
                                                product_name: name,
                                                product_price:
                                                  price_sale,

                                                ...(defaultSlot
                                                  ? {
                                                      hour_service:
                                                        defaultSlot,
                                                    }
                                                  : {}),
                                              },
                                              ...currentCart,
                                            ]);
                                          }
                                        } else {
                                          onLimitReachedCallback &&
                                            onLimitReachedCallback();
                                        }
                                      }}
                                      onRemoveCallback={() => {
                                        if (currentProduct) {
                                          if (tot > 1) {
                                            let { qty } =
                                              currentProduct;
                                            currentProduct = {
                                              ...currentProduct,
                                              qty: --qty,
                                            };
                                            setCart_([
                                              currentProduct,
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                          } else
                                            setCart_([
                                              ...currentCart.filter(
                                                (c) => c.id !== id,
                                              ),
                                            ]);
                                        }
                                      }}
                                      onResetCallback={(v) => {
                                        setCart_([
                                          ...currentCart.filter(
                                            (c) => c.id !== id,
                                          ),
                                        ]);
                                      }}
                                    />
                                  </div>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </div>
                    );
                  }
                },
              )}
            </div>
          )}

        {isTicketsSuccess && tickets?.length === 0 && (
          <span className="flex justify-center w-full">
            {t('drawers.book_product.steps.tickets.no_results')}
          </span>
        )}

        {tickets?.length > 0 && showTotal && (
          <>
            <div
              className={classNames(
                'absolute items-center justify-end',
                'hidden w-full md:flex px-14 bottom-20',
              )}
            >
              <div className="flex items-center space-x-4">
                <span className="text-lg xl:text-xl">TOTAL</span>
                <span
                  className={classNames(
                    'flex items-center',
                    'mx-1 text-2xl',
                    'font-bold text-center text-medium',
                  )}
                >
                  {[
                    ...clientCart?.products,
                    ...(serverCart?.products || []),
                  ]?.reduce(
                    (acc, { product: { price_sale: $ } }) =>
                      acc + $,
                    fullPrice,
                  )}
                  <EuroIcon
                    className="flex ml-1"
                    fontSize="small"
                  />
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

Tickets.displayName = 'Tickets';

export default Tickets;
