import { parseFormData } from '@utils';
import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS_API;

export const onLogin = async (payload) => {
  const path = `${baseUrl}/auth/login`;
  let response = {
    data: null,
    error: false,
  };

  try {
    await axios
      .post(path, parseFormData(payload))
      .then((res) => {
        response = { ...response, data: res?.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('onLogin', error);
    return { error };
  }
  return response;
};
export const onLogout = async (payload) => {
  const path = `${baseUrl}/auth/logout`;
  let response = {
    data: null,
    error: false,
  };
  try {
    await axios
      .post(path, parseFormData(payload))
      .then((res) => {
        response = { ...response, data: res?.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('onLogin', error);
    return { error };
  }
  return response;
};
export const onActivateUser = async ({ token }) => {
  const path = `${baseUrl}/users/activate`;
  let response = {
    data: null,
    error: false,
  };
  try {
    await axios
      .post(path, parseFormData({ token }))
      .then((res) => {
        response = { ...response, data: res?.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('onLogin', error);
    return { error };
  }
  return response;
};
export const onRecoverPassword = async (payload) => {
  const path = `${baseUrl}/users/password-recovery`;
  let response = {
    data: null,
    error: false,
  };
  try {
    await axios
      .post(path, parseFormData(payload))
      .then((res) => {
        response = { ...response, data: res?.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('onLogin', error);
    return { error };
  }
  return response;
};
