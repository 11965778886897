import { Cta, LazyImage } from '@components';
import Image from 'next/image';
import { useStrapiImageFormat } from '@hooks';
import classNames from 'classnames';
import last from 'lodash/last';

export default function HeroContent({
  description,
  img,
  isActive,
  label,
  title,
  subTitle,
  video,
  slideMode = false,
  singleAnimationIn = false,
  showDescription = true,
  cta,
}) {
  const containerClasses = classNames(
    'flex relative',
    'flex-col',
    'space-y-2',
    'md:space-y-4',
    'z-20',
    'select-none',
    'md:h-60',
    {
      'fade-out-bck': !isActive && !singleAnimationIn && slideMode,
      'fade-in-bck': isActive && !singleAnimationIn && slideMode,
      'fade-in-bck fake-class-to-avoid-classNames-bug':
        !isActive && singleAnimationIn && !slideMode,
    },
  );

  return (
    <>
      <div className={containerClasses}>
        <span className="max-w-xs mx-auto text-center label">
          {label}
        </span>
        <div className="container flex flex-col w-full space-y-8">
          <h1
            className={classNames(
              'flex',
              'flex-col',
              'z-10',
              'mx-auto',
              'text-cream-50',
              'font-bold',
              'text-center',
              'font-secondary',
              'text-5xl',
              'lg:text-7xl',
              'md:font-medium',
            )}
          >
            <span>
              {title?.split(' ')?.slice(0, -1)?.join(' ')}
            </span>
            <span className="italic">
              {last(title?.split(' '))}
            </span>
          </h1>

          {subTitle && (
            <h2
              className={classNames(
                'text-lg text-center',
                'text-primary-200',
                'font-light font-primary',
                'uppercase',
              )}
            >
              {subTitle}
            </h2>
          )}

          {showDescription && description && (
            <p
              className={classNames(
                'mx-auto',
                'text-center',
                'paragraph',
              )}
            >
              {description}
            </p>
          )}
          {cta && (
            <Cta
              label={cta?.label}
              href={cta?.href}
              externalLink={cta?.externalLink}
              onClick={cta?.ctaOnClick}
              className="max-w-xs mx-auto"
            />
          )}
        </div>
      </div>

      <div
        className={classNames(
          'absolute bottom-0',
          'w-screen',
          '-inset-y-[50px] inset-x-0 z-[1]',
          'bg-gradient-to-b',
          'from-black/25 via-black/80 to-black',
        )}
      />

      {img && (
        <LazyImage
          img={useStrapiImageFormat({ img })}
          alt="img"
          skeletonCls="absolute left-0 top-0 right-0 bottom-0"
          className={classNames(
            'object-cover',
            '-z-10',
            'w-full',
            'h-full',
            'absolute',
            'left-0',
            'top-0',
          )}
        />
      )}

      {video && !img && (
        <video
          src={video}
          loop
          muted
          autoPlay
          className={classNames(
            'object-cover',
            '-z-10',
            'w-screen',
            'h-screen',
            'max-w-screen',
            'absolute',
            'top-0',
            'bottom-0',
            'left-0',
            'right-0',
          )}
        />
      )}
    </>
  );
}
