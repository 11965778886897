import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect, useRef, useState } from 'react';

import HeroContent from './HeroContent';
import classnames from 'classnames';
import { useMediaQueries } from '@hooks';


export default function Hero({
  title = 'Lorem Ipsum',
  subTitle= null,
  label = 'Label',
  description,
  video,
  cta = null,
  small = false,
  slides,
  showDescription = true,
  hasSlider = slides?.length > 0 || true,
  img = 'https://images.unsplash.com/photo-1617079114138-9cf245e006c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=70',
}) {
  const sliderRef = useRef(null);
  const {isMobile, isDesktop} = useMediaQueries();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (sliderRef.current?.splide) {
      sliderRef.current.splide.on('active', (a) => {
        setIndex(a?.index);
      });
    }
  }, [sliderRef]);

  return (
    <div
      className={classnames(
        'bg-black',
        'w-screen',
        'hero relative z-20',
        'flex overflow-hidden ',
        {
          'items-center justify-center': !hasSlider,
          'h-[60vh] lg:h-[75vh]': small,
          'h-screen': !small,
        },
      )}
    >
      {hasSlider ? (
        <Splide
          tag="section"
          ref={sliderRef}
          options={{
            drag: true,
            type: 'slide',
            width: '100vw',
            height: '100%',
            autoplay: true,
            pagination: true,
            arrows: false,
            padding: 0,
            rewind: true,
            snap: true,
            perPage: 1,
            perMove: 1,
            focus: 0,
            arrows: false,
            flickPower: 800,
            flickMaxPages: 1,
            lazyLoad: 'nearby',
            video: {
              loop: true,
              mute: true,
            },
          }}
        >
          <div className={classnames('splide__progress')}>
            <div
              className={classnames(
                'splide__progress__bar',
                'absolute',
                'top-0',
                'z-[999]',
                'h-[1px]',
                'bg-primary-600',
              )}
            />
          </div>

          {slides?.length > 0 &&
            slides?.map((slide, i) => (
              <SplideSlide
                key={i}
                className={classnames(
                  'relative',
                  'w-screen',
                  'h-screen',
                  'lg:h-[95vh]',
                  'centered',
                )}
              >
                <HeroContent
                  {...slide}
                  slideMode={true}
                  isActive={index === i}
                  singleAnimationIn={false}
                  showDescription={showDescription}
                />
              </SplideSlide>
            ))}
        </Splide>
      ) : (
        <HeroContent
          isActive={false}
          slideMode={false}
          singleAnimationIn={true}
          showDescription={showDescription}
          {...{
            label,
            title,
            description,
            img,
            video,
            cta,
            subTitle,
          }}
        />
      )}
    </div>
  );
}
