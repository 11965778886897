import Badge from '@mui/material/Badge';

import { ShoppingCart } from '@mui/icons-material';
import {
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { onGetCart } from '@services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '@containers';
import { useEffect, useState } from 'react';

export default function CartButton() {
  const { push, locale } = useRouter();
  const { t } = useTranslation('common');
  const { user, cart: cartClient } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [serverCart, setServerCart] = useState(null);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 1,
      top: 2,
      border: `1px solid ${theme.palette.background.default}`,
      padding: '0',
      minWidth: 18,
      height: 18,
      fontSize: '0.4rem',
      fontSize: '0.70rem',
      // backgroundColor: theme.palette.primary.main
    },
  }));

  const onGetServerCart = async () => {
    try {
      setIsLoading(true);
      const { data } = await onGetCart(
        { locale },
        user.access_token,
      );

      if (data?.success) {
        setServerCart(data || { products: [] });
        setIsSuccess(true);
      } else {
        setServerCart({ products: [] });
        setIsSuccess(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('onGetServerCart', error);
      setServerCart({ products: [] });
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  const onClickIcon = () => push('/carrello');

  useEffect(() => {
    if (user?.logged) {
      onGetServerCart();
    }
  }, [user?.logged]);
  return (
    <>
      {/* Wrapped around fragment to avoid odd console error */}
      <Tooltip title={t('cart.cart')}>
        <IconButton aria-label="cart" onClick={onClickIcon}>
          <StyledBadge
            badgeContent={
              user?.logged && isSuccess
                ? serverCart?.products?.length
                : cartClient?.products?.length
            }
            color="primary"
            sx={{
              '& .MuiBadge-badge': {
                borderColor: '#da5f06 !important',
              },
            }}
          >
            {isLoading ? (
              <CircularProgress thickness={4} size={16} />
            ) : (
              <ShoppingCart
                fontSize="small"
                className="p-0 m-0 fill-white"
              />
            )}
          </StyledBadge>
        </IconButton>
      </Tooltip>
    </>
  );
}
