import classnames from 'classnames';

export default function Value({
  name,
  description,
}) {
  return (
    <div
      className={classnames(
        'relative',
        'flex',
        'flex-col',
        'px-8',
        'py-8',
        'space-y-4',
      )}
    >
      {name?.length > 0 && (
        <h2
          className={classnames(
            'text-sm',
            'text-center',
            'font-voice',
            'md:text-base',
            'label',
            'text-cream-50',
          )}
        >
          {name}
        </h2>
      )}
      <div
        className={classnames(
          'overflow-hidden',
          'rounded-2xl',
          'bg-gradient-to-r',
          'from-primary-500',
          'to-white/0',
          'h-0.5',
          'mt-6',
          'w-28',
          'mx-auto',
          'rounded-l-xl',
        )}
      >
        <div
          className={classnames(
            'bg-gradient-to-r',
            'from-primary-500',
            'w-6',
            'h-0.5',
            'rounded-2xl',
          )}
        />
      </div>

      {description?.length > 0 && (
        <p
          className={classnames(
            'flex-1',
            'max-w-xs',
            'mx-auto',
            'text-center',
            'paragraph',
          )}
        >
          {description}
        </p>
      )}
    </div>
  );
}
