import classnames from 'classnames';

export default function Header({
  label = 'Lorem Ipsum Dolor',
  title = 'Lorem Ipsum Dolor',
  description,
  centered,
  light,
  fullWidth,
  children,
}) {
  return (
    <div
      className={classnames('group relative mb-8 space-y-2 z-10', {
        'mx-auto text-center': centered,
        'w-full px-8 lg:px-0': fullWidth,
        'px-8 md:max-w-lg lg:px-0': !fullWidth,
      })}
    >
      {label?.length > 0 && (
        <span
          className={classnames(
            'relative w-44 block lg:w-fit',
            "after:absolute after:-right-16 after:bottom-1/2 after:block after:h-[0.5px] after:w-10 after:bg-primary-400 after:content-[''] after:lg:-right-14",
            {
              "mx-auto before:absolute before:-left-16 before:bottom-1/2 before:block before:h-[0.5px] before:w-10 before:bg-primary-400 before:content-[''] before:lg:-right-14":
                centered,
            },
          )}
        >
          <span className='label'>{label}</span>
        </span>
      )}

      {title?.length > 0 && (
        <h2
          className={classnames(
            'maw-w-md relative',
            'tracking-widest lg:tracking-wide',
            'font-secondary font-bold text-cream-50',
            'text-2xl md:text-3xl md:font-medium lg:text-5xl',
            {
              'text-black': light,
              'text-cream-50': !light,
            },
          )}
        >
          {title}
        </h2>
      )}

      {description?.length > 0 && (
        <p
          className={classnames(
            'mx-auto pt-8',
            'paragraph lg:max-w-2xl',
          )}
        >
          {description}
        </p>
      )}
      {children}
    </div>
  );
}
