import React, { useEffect, useState } from 'react';
import {
  getStrapiCities,
  getStrapiEvents,
  getStrapiLocations,
} from 'services/strapi';
import Filters from './Filters';
import Products from './Products';
import { Pagination } from '@mui/material';
import { styled } from '@mui/system';

const EventScrollableGallery = () => {
  const [filterData, setFilterData] = useState({
    cities: [],
    locations: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    city: [],
    location: [],
  });

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const Div = styled('div')(() => ({
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      width: '4px',
      height: '8px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e17f38',
      width: '4px',

      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
      marginLeft: '20vw',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
      marginRight: '20vw',
      borderRadius: '20px',
    },
  }));

  const onGetFilters = async () => {
    try {
      const { data: cities } = await getStrapiCities();
      const { data: locations } = await getStrapiLocations();
      setFilterData({
        cities: cities.map((elem) => elem?.attributes?.slug),
        locations: locations.map((elem) => elem?.attributes?.slug),
      });
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  const onFetchProducts = async (page = 1) => {
    setLoading(true);
    let filters = {};

    if (
      selectedFilters.city.length > 0 ||
      selectedFilters.location.length > 0
    ) {
      filters = {
        store: {
          ...(selectedFilters.city.length > 0 && {
            city: { slug: { $in: selectedFilters.city } },
          }),
          ...(selectedFilters.location.length > 0 && {
            location: { slug: { $in: selectedFilters.location } },
          }),
        },
      };
    }
    try {
      const { data, meta } = await getStrapiEvents(filters, {
        page,
      });

      setPageLimit(meta?.pagination?.pageCount);
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetFilters();
  }, []);

  useEffect(() => {
    onFetchProducts(page);
  }, [selectedFilters.city, selectedFilters.location, page]);

  if (!filterData.cities.length || !filterData.locations.length) {
    return null; // No filters available, show nothing
  }

  return (
    <>
      <div className="flex relative flex-col h-screen items-center lg:items-start lg:flex-row lg:justify-around max-w-7xl lg:mx-auto lg:space-x-12 mb-10">
        <div className=" items-center flex flex-col lg:flex-grow-1 lg:w-1/4">
          <Filters
            filterData={filterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          {pageLimit > 1 && (
            <Pagination
              count={pageLimit}
              color="primary"
              page={page}
              className="mt-8"
              onChange={(e, next) => {
                setPage(next);
              }}
            />
          )}
        </div>
        <Div
          className="relative lg:w-3/4 
         justify-start h-full flex-grow-5 overflow-auto"
        >
          <Products products={products} loading={loading} />
        </Div>
        <hr className=" lg:hidden bg-primary-500 w-1/4 h-1 mb-10 border-0 rounded-sm" />
      </div>
    </>
  );
};

export default EventScrollableGallery;
