import { useEffect, useState } from 'react';
import Slots from './../Slots';
import { getSlotsServiceBySlugAndDate } from '@services';

export default function SiteSlots({ slug, date, onClickSlot }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchSlots = async () => {
      if (slug && date) {
        setIsLoading(true);
        setIsFetching(true);
        try {
          const { data } = await getSlotsServiceBySlugAndDate({
            slug,
            date,
          });
          setData(data);
          setIsSuccess(true);
        } catch (error) {
          console.error('Failed to fetch slots data', error);
          setIsError(true);
        } finally {
          setIsLoading(false);
          setIsFetching(false);
        }
      }
    };

    fetchSlots();
  }, [slug, date]);

  if (slug && date && onClickSlot) {
    return (
      <Slots
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        onClickSlot={onClickSlot}
      />
    );
  }

  return null;
}
