import { useEffect, useState } from 'react';
import {
  CartList,
  CartActions,
  Header,
  EmptyCartMessage,
} from '@components';
import { useAppContext } from '@containers';
import { NextSeo } from 'next-seo';
import { Chip } from '@mui/material';
import { isEqual } from 'lodash';
import { DefaultLayout } from '@layouts';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  createOrder,
  deleteCartItem,
  deleteCartItems,
} from '@services';

const ChildCartComponent = ({
  user,
  clientCart,
  serverCart,
  isServerCartSuccess,
  refetch,
}) => {
  const { push } = useRouter();
  const {
    onShowLoginDialog,
    setReductionsIDs,
    removeCartItem,
    removeCartItems,
  } = useAppContext();
  const { t } = useTranslation('common');
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(true);
  const [allowPayment, setAllowPayment] = useState(false);
  const [cartHasReductions, setCartHasReductions] = useState(false);
  const [showDiscountsIDStepper, setShowDiscountIDStepper] =
    useState(false);

  const onCreateOrder = async () => {
    try {
      const { data } = await createOrder({
        invoice: 0,
        terms_and_conditions: 1,
        payment_method_id: null,
        reductions: JSON.stringify(user?.reductionsIDs),
      });

      if (data?.success && data?.status === 'success') {
        push('/carrello/thank-you');

        setReductionsIDs([]);
      }
    } catch (error) {
      console.error('onCreateOrder', error);
    }
  };

  const onHandlePayment = () => {
    try {
      if (user?.logged) push('/carrello/checkout');
      // else (showLoginDialogReducer(true));
    } catch (error) {
      console.error('onHandlePayment', error);
    }
  };
  const onDeleteSingleItem = async (id) => {
    try {
      if (user?.logged) {
        await deleteCartItem({ id }, user.access_token);
        refetch();
      } else {
        removeCartItem(id);
      }
    } catch (error) {
      console.error('onDeleteSingleItem', error);
    }
  };

  const onDeleteMultipleItems = async (ids) => {
    try {
      if (user?.logged) {
        await deleteCartItems(ids, user.access_token);
        refetch();
      } else removeCartItems(ids);
    } catch (error) {
      console.error('onDeleteMultipleItems', error);
    }
  };

  const onShowLogin = () => {
    onShowLoginDialog(!user?.onShowLogin);
  };
  useEffect(() => {
    if (
      user?.logged &&
      isServerCartSuccess &&
      serverCart?.products
    ) {
      if (!isEqual(cart, serverCart.products)) {
        setCart(serverCart.products);
        setLoader(false);
      }
    } else if (!user?.logged) {
      setCart(clientCart?.products || []);
      setLoader(false);
    }

    if (
      loader &&
      (serverCart?.products?.length === 0 ||
        clientCart?.products?.length === 0) &&
      cart?.length === 0
    ) {
      setLoader(false);
    }
  }, [clientCart, serverCart, user, cart, isServerCartSuccess]);

  useEffect(() => {
    if (cart?.length > 0) {
      const hasReductions = cart.some(
        ({ product }) =>
          product?.document_required || product?.reductions?.length,
      );
      const totalAmount = cart.reduce(
        (acc, { product: { price_sale: $ } }) => acc + $,
        0,
      );

      setTotal(totalAmount);
      setCartHasReductions(Boolean(hasReductions));
      setAllowPayment(!hasReductions);
    }
  }, [cart]);

  useEffect(() => {
    if (user?.logged) {
      setReductionsIDs([]);
    }
  }, [user.logged]);

  return (
    <>
      <NextSeo title={`Momento | Carrello`} />

      <DefaultLayout className=" mt-[80px]" showBookingCta={false}>
        <div className="container flex flex-col py-24">
          <Header
            label={t('cart.cart')}
            title={t('cart.complete_reservation')}
            centered
          />
          <div>
            {cart?.length === 0 && <EmptyCartMessage />}
            {cart?.length > 0 && (
              <>
                <div className="flex flex-col justify-between w-full h-auto mx-auto mb-20 space-y-5 lg:flex-row lg:space-x-5 lg:space-y-0">
                  <div className="w-full pt-0 space-y-2 md:px-4 lg:w-8/12">
                    <CartList
                      cart={cart}
                      isSuccess={isServerCartSuccess}
                      isLoading={loader}
                      isFetching={false}
                      onDeleteItem={onDeleteSingleItem}
                      onDeleteItems={onDeleteMultipleItems}
                      setAllowPayment={setAllowPayment}
                    />
                  </div>
                  <div className="flex w-full md:h-screen lg:w-4/12">
                    <div className="sticky w-full mx-auto top-28 h-96">
                      <div className="space-y-6 p-8 mt-16 bg-secondary-800/10 shadow-card rounded-xl">
                        <div className="w-full flex justify-center ">
                          <Chip
                            variant="outlined"
                            label={
                              <span className="font-medium">
                                {t('generic.labels.total')}
                              </span>
                            }
                            className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
                            sx={{
                              '& .MuiChip-label': {
                                padding: '16px 32px !important',
                              },
                            }}
                          />
                        </div>
                        <div className="w-full h-auto space-y-4">
                          <div className="top-0 flex flex-col justify-between leading-tight">
                            {cartHasReductions && !allowPayment && (
                              <div
                                role="alert"
                                className="flex items-center p-1 mb-4 rounded"
                              >
                                <strong className="text-sm font-normal">
                                  {t('cart.alert_reductions')}
                                </strong>
                              </div>
                            )}
                            <CartActions
                              user={user}
                              total={total}
                              allowPayment={allowPayment}
                              cartHasReductions={cartHasReductions}
                              onCreateOrder={onCreateOrder}
                              onShowLogin={onShowLogin}
                              onShowDiscountStepper={() =>
                                setShowDiscountIDStepper(true)
                              }
                              onHandlePayment={onHandlePayment}
                              isCreateOrderLoading={false}
                            />
                          </div>
                          <div>
                            <div className="flex flex-row justify-between p-5">
                              <span>
                                {t('generic.labels.total')}
                              </span>
                              <span className="text-lg font-semibold">
                                {total}
                                <span className="mx-1">euro</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default ChildCartComponent;
