import { useMediaQueries } from '@hooks';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { getUserData } from '@services';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

const UserPersonalData = ({ id, access_token }) => {
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation('common');

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onGetUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await getUserData({ id: id }, access_token);

      if (data?.success) {
        setUser(data?.data);
        setIsSuccess(true);
      } else {
        setUser(null);
        setIsSuccess(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('onGetUser', error);
      setUser(null);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    onGetUser();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full py-24">
          <CircularProgress thickness={4} size={20} />
        </div>
      ) : (
        isSuccess && (
          <List dense={isMobile}>
            <ListItem key={'name'}>
              <ListItemText
                secondary={t('forms.name')}
                primary={user?.first_name}
              />
            </ListItem>
            <ListItem key={'surname'}>
              <ListItemText
                secondary={t('forms.surname')}
                primary={user?.last_name}
              />
            </ListItem>
            <ListItem key={'email'}>
              <ListItemText
                secondary="Email"
                primary={user?.email}
              />
            </ListItem>
            {/* <ListItem>
              <ListItemText
                secondary={t('forms.type')}
                primary={t('site.users_type.private')}
              />
            </ListItem> */}
            <ListItem key={'active'}>
              <ListItemText
                secondary="Active"
                primary={
                  Boolean(user?.active)
                    ? t('forms.yes')
                    : t('forms.no')
                }
              />
            </ListItem>
          </List>
        )
      )}
    </>
  );
};

export default UserPersonalData;
