/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    path: '/location',
    label: { it: 'Location', en: 'Locations' },
  },
  {
    path: '/filosofia',
    label: { it: 'Filosofia', en: 'Philosophy' },
  },
  {
    path: '/eventi',
    label: { it: 'Eventi', en: 'Events' },
  },
  {
    path: '/4rooms',
    label: { it: '4rooms', en: '4rooms' },
  },
  {
    path: '/degustazioni',
    label: { it: 'Degustazioni', en: 'Tastings' },
  },
];
