export const MomentoLogo = ({ className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="2000.000000pt"
    height="200.000000pt"
    viewBox="0 0 2000.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
    fill="inherit"
    className={['', className].join(' ')}
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill="current"
      stroke="none"
    >
      <path
        d="M10 986 l0 -826 220 0 220 0 2 527 3 527 289 -269 c159 -149 292
-271 297 -273 4 -2 137 117 295 265 157 147 290 270 295 271 5 2 9 -227 9
-522 l0 -526 215 0 215 0 0 826 0 826 -132 -5 c-156 -5 -219 -21 -344 -82
-105 -51 -116 -61 -514 -432 l-36 -33 -199 186 c-212 197 -280 249 -379 294
-101 46 -172 61 -318 67 l-138 6 0 -827z"
      />
      <path
        d="M3651 1799 c-308 -62 -555 -301 -637 -614 -25 -95 -25 -304 -1 -394
46 -168 103 -267 227 -391 132 -132 238 -191 407 -225 120 -24 838 -17 928 9
148 44 263 115 371 229 423 444 242 1167 -341 1364 l-80 27 -415 2 c-228 2
-435 -2 -459 -7z m881 -452 c89 -43 157 -112 197 -197 23 -49 26 -68 26 -165
0 -100 -2 -115 -28 -167 -43 -88 -107 -152 -195 -195 l-76 -38 -371 0 -371 0
-76 38 c-89 43 -157 112 -197 197 -23 49 -26 67 -26 165 0 98 3 116 27 167 48
102 136 179 253 220 45 16 88 18 406 15 l355 -2 76 -38z"
      />
      <path
        d="M6130 986 l0 -826 220 0 220 0 2 526 3 526 290 -271 c160 -149 293
-271 296 -271 3 0 136 121 295 269 l289 269 3 -524 2 -524 220 0 220 0 0 826
0 827 -132 -6 c-73 -3 -160 -13 -193 -22 -71 -19 -188 -72 -248 -114 -38 -26
-276 -241 -408 -368 l-46 -44 -203 188 c-216 201 -262 238 -352 284 -99 50
-189 70 -340 76 l-138 6 0 -827z"
      />
      <path
        d="M12320 985 l0 -825 215 0 215 0 0 505 c0 278 2 505 5 505 3 0 204
-200 447 -444 401 -401 450 -447 513 -479 118 -59 194 -78 343 -84 l132 -6 0
827 c0 812 0 826 -20 826 -10 0 -43 -5 -72 -11 -125 -26 -242 -122 -301 -246
l-32 -68 -3 -343 c-2 -189 -7 -342 -11 -340 -4 2 -190 189 -412 417 -222 228
-430 434 -461 457 -118 87 -277 133 -460 134 l-98 0 0 -825z"
      />
      <path
        d="M18452 1799 c-341 -57 -621 -363 -660 -724 -48 -429 248 -830 666
-902 65 -11 168 -14 470 -11 l387 3 95 33 c110 39 172 72 255 139 133 108 241
277 286 448 30 112 30 289 0 400 -82 308 -308 534 -604 605 -75 18 -116 20
-462 19 -209 -1 -404 -5 -433 -10z m826 -429 c162 -57 265 -189 279 -358 16
-180 -107 -357 -285 -412 -58 -18 -93 -20 -392 -20 -298 0 -334 2 -392 20
-114 35 -217 130 -260 240 -30 77 -30 213 -1 288 46 114 143 205 259 242 53
17 94 19 397 19 313 1 342 0 395 -19z"
      />
      <path
        d="M9884 1765 c-145 -31 -266 -98 -373 -206 -81 -80 -128 -150 -168
-249 -49 -122 -53 -174 -53 -696 l0 -484 753 0 c499 0 773 4 813 11 153 28
278 148 314 301 5 24 10 53 10 66 l0 22 -725 0 -725 0 0 110 0 110 323 0 c354
0 418 7 508 57 104 56 191 188 193 293 l1 55 -512 3 -513 2 0 28 c0 15 15 52
33 82 25 42 44 61 86 82 l53 28 454 0 c297 0 471 4 506 11 106 23 199 87 257
177 29 46 61 144 61 189 l0 23 -617 -1 c-475 0 -632 -4 -679 -14z"
      />
      <path
        d="M15422 1761 c-65 -22 -148 -79 -185 -126 -42 -53 -78 -138 -84 -200
l-6 -55 362 0 361 0 2 -622 3 -623 218 -3 217 -2 0 624 0 624 208 4 c186 4
213 6 265 27 137 54 230 175 250 324 l7 47 -783 0 c-731 -1 -785 -2 -835 -19z"
      />
    </g>
  </svg>
);

export const Logo = ({ className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1000.000000pt"
    height="1010.000000pt"
    viewBox="0 0 1000.000000 1010.000000"
    preserveAspectRatio="xMidYMid meet"
    fill="inherit"
    className={['', className].join(' ')}
  >
    <g
      transform="translate(0.000000,1010.000000) scale(0.100000,-0.100000)"
      fill="inherit"
      stroke="none"
    >
      <path
        d="M2775 9979 c-579 -47 -1151 -274 -1605 -635 -109 -87 -377 -354 -464
-464 -182 -228 -374 -572 -471 -845 -86 -240 -143 -510 -165 -779 -6 -85 -10
-1327 -10 -3648 l0 -3518 3638 3 c3439 2 3643 3 3742 20 254 43 457 99 665
182 883 350 1547 1114 1775 2039 16 67 40 181 52 252 l23 129 2 3638 3 3637
-3542 -1 c-1949 -1 -3588 -5 -3643 -10z m3440 -2637 c230 -45 411 -105 599
-199 694 -347 1176 -1030 1282 -1813 18 -141 18 -439 -1 -587 -121 -922 -785
-1709 -1650 -1956 -292 -83 -403 -89 -1540 -84 -1019 4 -1003 3 -1257 67 -804
202 -1462 876 -1667 1710 -50 204 -65 331 -65 555 0 379 71 687 239 1030 123
255 276 466 481 666 365 355 812 570 1304 628 41 5 545 8 1120 7 1023 -2 1047
-3 1155 -24z"
      />
      <path
        d="M4140 6185 c-8 -2 -49 -9 -90 -15 -350 -57 -668 -289 -832 -606 -181
-350 -168 -786 34 -1121 183 -304 513 -511 883 -553 126 -14 1642 -13 1760 1
209 26 435 113 586 228 82 63 203 189 258 270 184 270 244 618 162 939 -98
383 -389 684 -779 806 -176 55 -194 56 -1117 55 -467 -1 -857 -3 -865 -4z"
      />
    </g>
  </svg>
);

export const Line = ({ className }) => {
  return (
    <svg
      fill="inherit"
      className={['', className].join(' ')}
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 426 120"
      //style="enable-background:new 0 0 426 120;"
      xmlSpace="preserve"
    >
      <g fill="current">
        <g class="st0">
          <path d="M360.79,67.35c-1.26-0.07-2.52-0.15-3.79-0.22c0.63,0.1,1.26,0.23,1.89,0.43C359.72,67.71,360.16,67.51,360.79,67.35z" />
          <path d="M353.5,66.91c-0.23-0.01-0.46-0.03-0.67-0.04c-0.2,0.02-0.4,0.04-0.58,0.06C352.67,66.93,353.09,66.92,353.5,66.91z" />
          <path d="M346.9,66.86c1.16,0.11,2.28,0.13,3.36,0.12C349.31,66.85,348.21,66.8,346.9,66.86z" />
          <path
            d="M352.14,67.33c-0.4-0.16-0.59-0.32,0.08-0.4c0.01,0,0.02,0,0.03,0c-0.66,0.01-1.31,0.03-1.99,0.04
			C350.97,67.06,351.6,67.18,352.14,67.33z"
          />
          <path d="M352.14,67.33c0.38,0.16,1,0.31,1.21,0.4C353,67.59,352.6,67.46,352.14,67.33z" />
          <path
            d="M350.32,70.64c-0.67,0.08-0.47-0.88-1.79-0.72c-3.1,0.13-5.76,0.44-8.65,0.3c-1.32,0.15-3.35-1.01-4.67-0.85
			c-3.11,0.13-11.06,1.05-6.41,1.21c7.01,0.18,11.58,0.13,17.87,0.24c5.12-0.15,10.05-0.38,15.29-0.49c1.47,0.02,2.83,0.16,4.2,0.29
			c-1.79-0.22-3.62-0.45-5.65-0.7C357.83,69.54,354.54,70.62,350.32,70.64z"
          />
          <path
            d="M388.66,69.22c-0.66,0.08-1.99,0.23-1.99,0.23c-2.91-0.83-6.01-0.7-9.11-0.57c0.44,0.18,0.89,0.36,1.35,0.54
			c1.77,0.03,1.98-0.23,0.43-0.51c3.57,0.75,6.96,0.99,10.44,1.06C389.57,69.69,389.63,69.39,388.66,69.22z"
          />
          <path
            d="M391.82,69.98c-0.68-0.01-1.37-0.01-2.04-0.02c0.01,0.03,0.05,0.06,0.07,0.09c0.69-0.02,1.38-0.03,2.05-0.04L391.82,69.98
			z"
          />
          <path
            d="M332.88,67.09c-8.77-0.37-17.02-0.79-25.08-1.26c-0.63,0.16-1.07,0.36-1.9,0.2c-0.62-0.2-1.25-0.33-1.88-0.43
			c-1.18-0.07-2.35-0.14-3.52-0.21c-0.41,0.01-0.82,0.01-1.24,0.02c-0.01,0-0.02,0-0.03,0c-0.67,0.08-0.48,0.24-0.07,0.4
			c0.45,0.12,0.86,0.26,1.22,0.4c-0.22-0.09-0.82-0.25-1.22-0.4c-0.55-0.15-1.16-0.27-1.88-0.36c-1.08,0.01-2.2-0.01-3.36-0.12
			c1.29-0.05,2.4,0,3.36,0.12c0.68-0.01,1.34-0.03,1.99-0.04c0.19-0.02,0.38-0.04,0.58-0.06c-2.69-0.17-5.35-0.34-8.03-0.51
			c-0.62,0-1.21,0-1.69,0c-1.94,0.04-3.88,0.08-5.06-0.44c-0.47-0.03-0.95-0.06-1.43-0.1c0.09,0.03,0.18,0.07,0.27,0.1
			c-0.21-0.04-0.39-0.08-0.53-0.12c-2.02-0.13-4.04-0.27-6.07-0.4c-1.32,0.12-2.69,0.28-4.05,0.36c0,0-0.9-0.36-1.34-0.54
			c-0.56,0.21-1.36,0.34-2.28,0.38c1.08,0.34,2.06,0.69,2.99,0.93c1.34,0.54,3.09-0.82,4.66,0.15c-1.57-0.98-3.31,0.39-4.66-0.15
			c-0.92-0.24-1.9-0.6-2.99-0.93c-1.93,0.09-4.4-0.19-6.39-0.73c2.5-0.31,4.58,0.17,6.39,0.73c0.93-0.04,1.72-0.16,2.28-0.38
			c0.44,0.18,1.34,0.54,1.34,0.54c1.36-0.08,2.73-0.24,4.05-0.36c-3.97-0.26-7.97-0.51-12.07-0.78
			c-13.17-0.85-25.91-1.24-38.75-0.99c-12.84,0.22-25.76,0.83-39.44,1.23c-14.83,0.42-29.07,0.68-43.06,0.78
			c-27.89,0.19-53.21,2.68-81.45,1.58c-10.83-0.34-30.84,1.06-41.24,1.65c-13.87,2.25-6.44,6.02-5.78,6.23
			c8.73,3.1,15.35,0.84,32.91,2.08c-11.95-0.61,8.67-2.67,20.01-0.8c16.42,2.9,39.46-1.13,59.55-1.13c5.02,0,10.34-0.42,16.34-0.63
			c15.56-0.11,30.54-0.89,45.71-1.8c15.19-0.88,30.51-2.09,46.66-2.05c8.55-0.05,16.11-0.74,23.39-0.97
			c5.31,0.02,10.96,0.75,15.29,0.94c6.8,0.3,12.68,0.24,18.31,0.08c-6.28-0.11-10.87-0.07-17.87-0.25c-4.66-0.15,3.29-1.08,6.4-1.21
			c1.33-0.15,3.36,1.01,4.69,0.85c2.87,0.13,5.53-0.18,8.63-0.31c1.33-0.15,1.14,0.8,1.8,0.72c4.22-0.02,7.51-1.1,10.19-0.72
			c2.03,0.25,3.87,0.48,5.65,0.7c1.13,0.11,2.26,0.2,3.46,0.22c0.75-0.04,1.52-0.09,2.3-0.14c-0.6-0.32-0.8-0.69-0.99-1.06
			c1.11,0.1,2.67,0.39,3.77,0.49c-1.11-0.1-2.66-0.39-3.77-0.49c0.19,0.37,0.39,0.74,0.99,1.06c5.56-0.31,11.97-0.52,17.92-0.66
			c-0.02-0.03-0.05-0.06-0.08-0.09c-3.48-0.06-6.86-0.31-10.43-1.06c1.55,0.28,1.34,0.54-0.44,0.52c-0.45-0.18-0.9-0.36-1.34-0.54
			c3.11-0.13,6.21-0.26,9.1,0.57c0,0,1.33-0.15,1.99-0.23c0.97,0.18,0.92,0.48,1.11,0.74c0.68,0.01,1.36,0.01,2.05,0.02
			C337.4,67.91,332.88,67.09,332.88,67.09z"
          />
          <path d="M297.19,65.42c0.47,0,1.06,0,1.68,0c-2.24-0.15-4.49-0.29-6.74-0.44C293.3,65.5,295.24,65.46,297.19,65.42z" />
        </g>
      </g>
    </svg>
  );
};

export const MomentoPin = ({ className }) => {
  return (
    <svg
      fill="inherit"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className={['', className].join(' ')}
    >
      <path
        d="m25,4.92c-6.9,0-12.5,5.6-12.5,12.5,0,9.19,12.5,27.67,12.5,27.67,0,0,12.5-18.71,12.5-27.67,0-6.9-5.6-12.5-12.5-12.5Zm0,19.21c-3.49,0-6.33-2.83-6.33-6.33s2.83-6.33,6.33-6.33,6.33,2.83,6.33,6.33-2.83,6.33-6.33,6.33Z"
        fill="inherit"
      />
    </svg>
  );
};

export const FouRoomsLogo = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="490.000000pt"
      height="78.000000pt"
      viewBox="0 0 490.000000 78.000000"
      preserveAspectRatio="xMidYMid meet"
      className={['', className].join(' ')}
      fill="inherit"
    >
      <g
        transform="translate(0.000000,78.000000) scale(0.100000,-0.100000)"
        fill="inherit"
        stroke="none"
      >
        <path
          d="M0 581 l0 -201 55 0 55 0 0 126 0 126 71 -66 72 -65 71 65 71 65 3
-126 3 -125 54 0 55 0 0 200 0 200 -35 0 c-48 0 -102 -24 -145 -63 -84 -78
-75 -76 -123 -30 -65 62 -102 83 -157 90 l-50 6 0 -202z"
        />
        <path
          d="M860 767 c-19 -6 -51 -28 -71 -48 -97 -97 -70 -257 53 -320 57 -29
254 -28 316 2 113 55 143 210 59 306 -51 58 -95 73 -217 72 -58 -1 -121 -6
-140 -12z m267 -119 c41 -41 39 -100 -5 -139 -30 -27 -38 -29 -117 -29 -95 0
-130 12 -151 51 -23 46 -17 78 21 116 l35 35 95 -4 c88 -3 97 -5 122 -30z"
        />
        <path
          d="M1500 580 l0 -200 55 0 55 0 0 126 0 126 73 -65 72 -64 73 65 72 65
0 -127 0 -126 55 0 55 0 0 200 0 200 -35 0 c-53 0 -99 -23 -159 -80 -29 -27
-57 -50 -61 -50 -4 0 -32 23 -61 50 -60 57 -106 80 -159 80 l-35 0 0 -200z"
        />
        <path
          d="M3020 580 l0 -200 55 0 55 0 0 122 0 123 113 -112 c115 -115 143
-133 203 -133 l34 0 0 200 c0 133 -4 200 -10 200 -22 0 -70 -31 -81 -52 -6
-12 -14 -59 -17 -105 l-5 -85 -111 111 c-114 113 -142 131 -202 131 l-34 0 0
-200z"
        />
        <path
          d="M4485 762 c-139 -66 -156 -246 -32 -346 38 -31 41 -31 165 -34 116
-3 131 -1 172 20 26 13 57 41 75 66 25 37 30 53 30 106 0 52 -5 70 -30 111
-46 73 -85 89 -225 93 -96 2 -122 -1 -155 -16z m254 -96 c57 -30 68 -110 22
-157 -28 -28 -33 -29 -125 -29 -90 0 -97 2 -127 28 -23 21 -31 37 -32 64 -1
41 24 85 56 98 36 14 175 12 206 -4z"
        />
        <path
          d="M2380 751 c-19 -10 -48 -35 -65 -55 -30 -38 -30 -38 -33 -182 l-3
-144 201 0 202 0 29 29 c16 16 29 39 29 50 0 20 -4 21 -180 21 l-180 0 0 24
c0 24 2 24 100 28 91 3 102 5 124 28 13 14 27 35 31 48 6 22 6 22 -119 22
-103 0 -126 3 -126 14 0 35 40 46 168 46 123 0 124 0 153 29 57 57 48 61 -134
61 -139 0 -167 -3 -197 -19z"
        />
        <path
          d="M3749 741 c-50 -50 -41 -60 59 -63 l87 -3 3 -152 3 -153 49 0 49 0 3
153 3 152 63 5 c54 4 67 9 88 33 48 56 44 57 -177 57 l-201 0 -29 -29z"
        />
        <path
          d="M169 195 c-34 -18 -49 -61 -49 -136 0 -59 0 -59 30 -59 28 0 30 2 30
39 l0 40 51 3 c41 2 55 8 67 26 l14 22 -67 0 c-49 0 -66 3 -63 13 3 7 33 14
82 17 72 5 95 15 96 43 0 13 -164 7 -191 -8z"
        />
        <path
          d="M784 191 c-66 -41 -63 -140 5 -175 38 -20 123 -21 161 -1 63 33 76
117 24 166 -35 33 -144 39 -190 10z m156 -51 c23 -23 25 -39 7 -64 -19 -26
-99 -33 -132 -12 -32 21 -33 59 -3 80 33 23 103 21 128 -4z"
        />
        <path
          d="M1394 196 c-15 -12 -19 -27 -19 -75 0 -81 28 -112 108 -119 65 -5
109 14 132 58 16 32 21 150 6 150 -22 0 -41 -34 -41 -71 0 -72 -71 -113 -128
-73 -19 13 -22 24 -22 80 0 67 -4 73 -36 50z"
        />
        <path
          d="M2039 181 c-27 -27 -29 -35 -29 -105 0 -74 1 -76 25 -76 20 0 25 5
25 25 0 21 5 25 31 25 19 0 43 -10 61 -25 17 -14 42 -25 59 -25 l30 0 -19 28
-20 27 24 28 c28 33 29 47 9 86 -18 36 -32 41 -107 41 -53 0 -63 -3 -89 -29z
m156 -51 c0 -24 -2 -25 -65 -25 -59 0 -65 2 -62 20 6 29 21 36 77 33 46 -3 50
-5 50 -28z"
        />
        <path
          d="M2658 181 c-28 -25 -33 -36 -33 -75 0 -69 34 -99 118 -104 101 -6
147 26 147 103 0 73 -42 105 -139 105 -53 0 -65 -4 -93 -29z m160 -37 c12 -8
22 -26 22 -39 0 -33 -36 -55 -87 -55 -45 0 -83 25 -83 55 0 8 9 24 20 35 25
25 95 27 128 4z"
        />
        <path
          d="M3284 176 c-45 -45 -47 -92 -6 -137 24 -27 37 -33 85 -37 31 -2 68
-1 82 2 90 23 102 152 18 195 -12 6 -49 11 -83 11 -57 0 -65 -3 -96 -34z m164
-32 c30 -21 29 -59 -3 -80 -33 -21 -113 -14 -132 12 -18 25 -16 41 7 64 25 25
95 27 128 4z"
        />
        <path
          d="M3890 106 l0 -106 25 0 c24 0 25 2 25 66 l0 66 35 -31 c20 -17 39
-31 42 -31 4 0 22 14 40 32 l33 32 0 -68 0 -67 28 3 27 3 0 100 c0 94 -1 100
-21 103 -12 2 -40 -12 -66 -31 l-45 -35 -29 29 c-16 16 -44 31 -61 35 l-33 7
0 -107z"
        />
        <path
          d="M4552 194 c-15 -10 -22 -25 -22 -48 0 -49 24 -64 104 -68 44 -2 71
-8 74 -15 3 -10 -17 -13 -75 -13 -78 0 -103 -9 -103 -37 0 -10 23 -13 93 -13
106 0 137 16 137 70 0 43 -31 60 -112 60 -54 0 -68 3 -68 15 0 12 14 15 68 15
67 0 92 10 92 37 0 20 -159 18 -188 -3z"
        />
      </g>
    </svg>
  );
};
