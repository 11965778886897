import { Euro } from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import React from 'react';

const Prices = ({ onOpenAddToCartDrawer, tickets }) => {
  return (
    <div className="bg-secondary-800/10 p-8   md:w-96 w-full shadow-card rounded-xl flex flex-col items-center space-y-6">
      <Chip
        variant="outlined"
        label={'biglietti'}
        className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
        sx={{
          '& .MuiChip-label': {
            padding: '16px 32px !important',
          },
        }}
      />
      <div className="w-full space-y-4">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="w-full flex justify-between"
          >
            <p className="w-full">Nome: {ticket.name}</p>
            <p className="flex space-x-2">
              <span>{ticket.price_sale}</span>
              <Euro fontSize="small" />
            </p>
          </div>
        ))}
      </div>
      <Button
        variant="contained"
        fullWidth
        onClick={(e) => {
          onOpenAddToCartDrawer(e);
        }}
        className="h-12"
        sx={{
          backgroundColor: '#e17f38 !important',
          '&:hover': {
            backgroundColor: '#da5f06 !important',
          },
        }}
      >
        acquista
      </Button>
    </div>
  );
};

export default Prices;
