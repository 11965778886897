import AcUnitIcon from '@mui/icons-material/AcUnit';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import BathtubIcon from '@mui/icons-material/Bathtub';
import DeckIcon from '@mui/icons-material/Deck';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import ShowerIcon from '@mui/icons-material/Shower';
import Tooltip from '@mui/material/Tooltip';
import TvIcon from '@mui/icons-material/Tv';
import WeekendIcon from '@mui/icons-material/Weekend';
import WifiIcon from '@mui/icons-material/Wifi';
import classnames from 'classnames';
import { useMediaQueries } from '@hooks';

export default function RoomFeatures({ isOdd, facilities }) {
  const { isMobile } = useMediaQueries();

  const icons = [
    {
      slug: 'breakfast',
      icon: (
        <BakeryDiningIcon
          fontSize={isMobile ? 'small' : 'medium'}
        />
      ),
    },
    {
      slug: 'wifi',
      icon: <WifiIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
    {
      slug: 'bar',
      icon: (
        <LocalBarIcon fontSize={isMobile ? 'small' : 'medium'} />
      ),
    },
    {
      slug: 'parking',
      icon: (
        <LocalParkingIcon
          fontSize={isMobile ? 'small' : 'medium'}
        />
      ),
    },
    {
      slug: 'family',
      icon: (
        <FamilyRestroomIcon
          fontSize={isMobile ? 'small' : 'medium'}
        />
      ),
    },
    {
      slug: 'terrace',
      icon: <DeckIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
    {
      slug: 'shower',
      icon: <ShowerIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
    {
      slug: 'couch',
      icon: (
        <WeekendIcon fontSize={isMobile ? 'small' : 'medium'} />
      ),
    },
    {
      slug: 'single',
      icon: <PersonIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
    {
      slug: 'double',
      icon: (
        <PeopleAltIcon fontSize={isMobile ? 'small' : 'medium'} />
      ),
    },
    {
      slug: 'air',
      icon: <AcUnitIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
    {
      slug: 'quadruple',
      icon: (
        <span className="relative">
          <PeopleAltIcon fontSize={isMobile ? 'small' : 'medium'} />
          <span className="absolute text-sm -right-3 -top-2">
            x2
          </span>
        </span>
      ),
    },
    {
      slug: 'bath',
      icon: (
        <BathtubIcon fontSize={isMobile ? 'small' : 'medium'} />
      ),
    },
    {
      slug: 'tv',
      icon: <TvIcon fontSize={isMobile ? 'small' : 'medium'} />,
    },
  ];

  return Object.entries(facilities)?.length > 0 ? (
    <ul
      className={classnames(
        'flex flex-wrap md:flex-row md:space-x-6',
        {
          'md:pl-1': isOdd,
          'md:pr-1': !isOdd,
        },
      )}
    >
      {Object.entries(facilities)?.map(([key, value], i) => {
        const hasIcon = icons?.find((i) => i?.slug === key);

        return (
          hasIcon &&
          value && (
            <li className="m-3 md:m-2.5" key={i}>
              <Tooltip
                title={<span className="font-light">{key}</span>}
              >
                {hasIcon?.icon}
              </Tooltip>
            </li>
          )
        );
      })}
    </ul>
  ) : null;
}
