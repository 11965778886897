import classnames from 'classnames';

export default function Download({
  label = 'Download',
  className,
  href = null,
}) {
  return (
    href && (
      <a
        href={href}
        target="_blank"
        download="menu"
        className={classnames(
          className,
          'relative rounded-sm',
          'flex items-center justify-center',
          'group shadow',
          'z-50 h-9 w-full overflow-clip',
          'rounded-sm bg-cream-50',
        )}
      >
        <div
          className={classnames(
            'absolute inset-0',
            'transition-all duration-[250ms] ease-in-out',
            'w-0 bg-primary-500',
            'rounded-sm',
            'group-hover:w-full',
          )}
        />
        <span
          className={classnames(
            'relative text-sm uppercase text-black/80 lg:text-base',
            'transition-all duration-[250ms] ease-in-out',
            'group-hover:text-white',
            'rounded-sm',
          )}
        >
          {label}
        </span>
      </a>
    )
  );
}
