import { PictureAsPdf } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { getOrders } from '@services';
import { useTranslation } from 'next-i18next';
import React, { useState, useEffect } from 'react';

const OrdersList = ({ access_token }) => {
  const { t } = useTranslation('common');
  const [orders, setOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onGetOrder = async () => {
    try {
      setIsLoading(true);
      const { data } = await getOrders(access_token);
      if (data?.success) {
        setOrders(data.data);
        setIsSuccess(true);
      } else {
        setOrders(null);
        setIsSuccess(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('onGetOrder', error);
      setOrders(null);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  useEffect(() => {
    onGetOrder();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center w-full py-24">
          <CircularProgress thickness={4} size={20} />
        </div>
      )}{' '}
      {isSuccess && orders?.length > 0 && (
        <div className="space-y-4">
          {[...orders].reverse().map((order, i) => {
            const {
              tickets,
              order_number,
              total_price,
              items,
              created_at,
            } = order;

            const minDate = minBy(items, 'date_service');

            const allowPrintTicket = items.filter((item) => {
              const { date_service, hour_service } = item;
              const date = date_service + ' ' + hour_service;
              return dayjs().isSameOrBefore(dayjs(date));
            });

            return (
              <div
                key={i}
                className="relative p-6 rounded-md bg-slate-100 dark:bg-zinc-800"
              >
                <span className="absolute text-sm opacity-50 right-8 top-5">
                  {dayjs(created_at?.date).fromNow()}
                </span>

                <List dense={true}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <span className="text-green-600">
                          {order_number}
                        </span>
                      }
                      secondary={t(
                        'pages.personal_area.orders.number',
                      )}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={dayjs(created_at?.date).format(
                        'DD/MM/YYYY',
                      )}
                      secondary={t(
                        'pages.personal_area.orders.created',
                      )}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={dayjs(minDate?.date_service).fromNow(
                        true,
                      )}
                      secondary={t(
                        'pages.personal_area.orders.first_entry',
                      )}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={items.reduce(
                        (acc, item) => acc + item?.qty,
                        0,
                      )}
                      secondary={t(
                        'pages.personal_area.orders.quantity',
                      )}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<span>{total_price} euro</span>}
                      secondary={t(
                        'pages.personal_area.orders.total',
                      )}
                    />
                  </ListItem>

                  {allowPrintTicket?.length > 0 && (
                    <Button
                      startIcon={<PictureAsPdf fontSize="large" />}
                      size="small"
                      target="_blank"
                      rel="noreferrer"
                      variant="link"
                      disableFocusRipple
                      disableElevation
                      fullWidth
                      href={'https://' + tickets}
                      title="tickets"
                      className="flex max-w-sm mx-auto text-xs font-medium"
                      download={'https://' + tickets}
                    >
                      Scarica biglietti
                    </Button>
                  )}
                </List>
              </div>
            );
          })}
        </div>
      )}
      {isSuccess && orders?.length === 0 && (
        <div className="h-100 flex w-full justify-center items-center">
          {t('pages.personal_area.no_orders')}
        </div>
      )}
    </>
  );
};

export default OrdersList;
