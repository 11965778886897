import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import React, { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';

const Collapsible = ({ title, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={className}>
      <IconButton
        onClick={handleToggle}
        size="small"
        disableFocusRipple
        disableRipple
        className="relative max-w-2xl space-x-2 text-white rounded-none"
      >
        <span className="text-base font-medium xl:text-lg label text-primary-300">{title}</span>
        {isOpen ? (
          <ExpandLessIcon className="absolute -right-8 fill-white" />
        ) : (
          <ExpandMoreIcon className="absolute -right-8 fill-white" />
        )}
      </IconButton>

      <Collapse in={isOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default Collapsible;
