import { parseFormData } from '@utils';
import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS_API + '/users';

const prepareHeaders = (access_token) => {
  const headers = {};
  const token = access_token;
  if (token) {
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export const getUserData = async ({ id }, access_token) => {
  const path = `${baseUrl}/external-user/${id}`;
  let response = {
    data: null,
    error: false,
  };
  const headers = prepareHeaders(access_token);

  try {
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getUserData', error);
    return { error };
  }
  return response;
};

export const registerUser = async ({ data }, access_token) => {
  const path = `${baseUrl}/external-user/`;
  let response = {
    data: null,
    error: false,
  };
  const headers = prepareHeaders(access_token);

  try {
    await axios
      .post(path, parseFormData(data), { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('registerUser', error);
    return { error };
  }
  return response;
};

export const updateUserData = async (
  { id, data },
  access_token,
) => {
  const path = `${baseUrl}/external-user/${id}`;
  let response = {
    data: null,
    error: false,
  };
  const headers = prepareHeaders(access_token);
  try {
    await axios
      .put(path, data, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('updateUserData', error);
    return { error };
  }
  return response;
};

export const resetPassword = async ({ data }, access_token) => {
  const path = `${baseUrl}/password-reset`;
  let response = {
    data: null,
    error: false,
  };
  const headers = prepareHeaders(access_token);

  try {
    await axios
      .post(path, parseFormData(data), { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('resetPassword', error);
    return { error };
  }
  return response;
};
