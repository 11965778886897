import React, { useState } from 'react';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { createOrder, validatePayment } from '@services';
import { useAppContext } from '@containers';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const CheckoutForm = ({ serverCart, isServerCartSuccess }) => {
  const { user } = useAppContext();
  const { t } = useTranslation('common');
  const [stripeTerms, setStripeTerms] = useState(false);
  const [momentoTerms, setMomentoTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethodID = async () => {
    try {
      const { error, paymentMethod } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

      if (error) console.error('getPaymentMethodID', error);
      return { error, paymentMethod };
    } catch (error) {
      console.error('getCreatePaymentMethodID', error?.message);
    }
  };

  const handleValidatePayment = async ({
    payment_method_id,
    id,
    amount,
  }) => {
    setIsPaymentLoading(true);
    try {
      const { data: data } = await validatePayment(
        {
          email: user?.email,
          amount,
          payment_method_id,
          payment_intent_id: id,
        },
        user?.access_token,
      );

      const {
        success,
        payment_intent_id,
        requires_action,
        payment_intent_client_secret,
      } = data;
      setIsPaymentLoading(false);
      return {
        success,
        payment_intent_id,
        requires_action,
        payment_intent_client_secret,
      };
    } catch (error) {
      setIsPaymentLoading(false);
      console.error('handleValidatePaymentError', error?.message);
    }
  };

  const handleCreateOrder = async ({
    payment_intent,
    payment_method_id,
  }) => {
    setIsOrderLoading(true);
    try {
      const { data } = await createOrder({
        invoice: 0,
        terms_and_conditions: 1,
        payment_intent,
        payment_method_id,
        ...(user?.reductionsIDs?.length > 0
          ? {
              reductions: JSON.stringify(user?.reductionsIDs),
            }
          : {}),
      });

      if (data?.success && data?.status === 'success') {
        setIsOrderLoading(false);
        setIsOrderSuccess(true);
        dispatch(setReductionsIDsReducer([]));
        push('/carrello/thank-you');
      } else {
        setIsOrderLoading(false);

        dispatch(setReductionsIDsReducer([]));
        push('/carrello/error');
      }
    } catch (error) {
      setIsOrderLoading(false);

      console.error('handleCreateOrderError', error?.message);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);

      if (elements == null) return;

      const {
        paymentMethod: { id },
      } = await getPaymentMethodID();

      if (
        id &&
        isServerCartSuccess &&
        serverCart?.products?.length > 0
      ) {
        const {
          success,
          payment_intent_id,
          requires_action,
          payment_intent_client_secret,
        } = await handleValidatePayment({
          payment_method_id: id,
          id: null,
          amount: serverCart?.products?.reduce(
            (acc, { product: { price_sale: $ } }) => acc + $,
            0,
          ),
        });

        if (success && payment_intent_id) {
          await handleCreateOrder({
            payment_intent: payment_intent_id,
            payment_method_id: id,
          });
        }

        if (requires_action && payment_intent_client_secret) {
          const { paymentIntent } = await stripe.handleCardAction(
            payment_intent_client_secret,
          );

          if (paymentIntent) {
            const { success, payment_intent_id } =
              await handleValidatePayment({
                id: paymentIntent?.id,
              });

            if (success && payment_intent_id) {
              await handleCreateOrder({
                payment_intent: payment_intent_id,
                payment_method_id: id,
              });
            }
          }
        }
      }
    } catch (error) {
      console.error('handleSubmit CheckOut', error);
    }
  };
  return (
    <div className="my-8 max-w-5xl mx-auto">
      {/* <HandleFormResponse
          className="items-center justify-center max-w-md mx-auto"
          data={data || validatePayment}
          isError={isError || isValidatePaymentError}
          isOrderSuccess={isOrderSuccess}
          skipSuccess={true}
        /> */}
      {(isOrderLoading || isPaymentLoading) && (
        <div className="flex items-center justify-center w-full h-20 p-10 md:h-40">
          <CircularProgress thickness={4} size={20} />
        </div>
      )}
      {/* SHOW PAYMENT FORM */}
      {!isOrderSuccess && !isOrderLoading && !isPaymentLoading && (
        <form
          onSubmit={handleSubmit}
          className="mt-8 mb-24 space-y-8"
        >
          <div className="max-w-lg mx-auto bg-secondary-800/10 p-8 text-white  shadow-card rounded-xl ">
            <CardElement
              options={{
                style: {
                  base: {
                    color: '#fff',
                    '::placeholder': {
                      color: '#bfbfbf',
                    },
                  },
                },
              }}
            />
          </div>
          <div className="flex flex-col justify-center w-full max-w-lg px-2 mx-auto my-4">
            <FormControl>
              <FormControlLabel
                label={
                  <span className="text-xs md:text-sm">
                    {t(
                      'pages.checkout.accept_payment_conditions.text',
                    )}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="mx-1 font-semibold underline underline-offset-4"
                      href="https://stripe.com/it-us/legal/end-users"
                    >
                      {t(
                        'pages.checkout.accept_payment_conditions.link',
                      )}
                    </a>
                  </span>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={stripeTerms}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setStripeTerms(e.target.checked);
                    }}
                  />
                }
              />
            </FormControl>

            <FormControl className="">
              <FormControlLabel
                label={
                  <span className="text-xs md:text-sm">
                    {t(
                      'pages.checkout.accept_service_conditions.text',
                    )}
                    <a
                      className="mx-1 font-semibold underline underline-offset-4"
                      href={'/privacy-policy'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(
                        'pages.checkout.accept_service_conditions.link',
                      )}
                    </a>
                  </span>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={momentoTerms}
                    onChange={(e) =>
                      setMomentoTerms(e.target.checked)
                    }
                  />
                }
              />
            </FormControl>
          </div>

          <Button
            fullWidth
            type="submit"
            disabled={
              loading ||
              !stripeTerms ||
              !momentoTerms ||
              !stripe ||
              !elements
            }
            className={classNames(
              'mx-auto flex max-w-sm justify-center text-white',
              {
                'pointer-events-none opacity-30':
                  !stripeTerms ||
                  !momentoTerms ||
                  !stripe ||
                  !elements,
              },
            )}
            sx={{
              backgroundColor: '#e17f38 !important',
              '&:hover': {
                backgroundColor: '#da5f06 !important',
              },
            }}
          >
            {t('buttons.pay')}
          </Button>
        </form>
      )}
    </div>
  );
};

export default CheckoutForm;
