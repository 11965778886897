import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS_API + '/services/';

const prepareHeaders = (access_token) => {
  const headers = {};
  const token = access_token;
  if (token) {
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export const getScheduleServiceBySlug = async (
  slug,
  access_token,
) => {
  const path = `${baseUrl}${slug}/schedule`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getScheduleServiceBySlug', error);
    return { error };
  }
  return response;
};

export const getSlotsServiceBySlugAndDate = async (
  { slug, date },
  access_token,
) => {
  const path = `${baseUrl}${slug}/slots/${date}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getSlotsServiceBySlugAndDate', error);
    return { error };
  }
  return response;
};

export const getServiceTickets = async (
  { slug, locale = 'it' },
  access_token,
) => {
  const path = `${baseUrl}${slug}/tickets?lang=${locale}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getServiceTickets', error);
    return { error };
  }
  return response;
};

export const getServiceTicketsByDateAndHour = async (
  { slug, date, hour, locale = 'it' },
  access_token,
) => {
  const path = `${baseUrl}${slug}/tickets?hour_service=${hour}&date_service=${date}&lang=${locale}`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .get(path, { headers })
      .then((res) => {
        response = { ...response, data: res.data?.data || [] };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('getServiceTicketsByDateAndHour', error);
    return { error };
  }
  return response;
};

export const requestBooking = async (
  { slug, data },
  access_token,
) => {
  const path = `${baseUrl}${slug}/request-info/email`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    await axios
      .post(path, data, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('requestBooking', error);
    return { error };
  }
  return response;
};
