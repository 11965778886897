import { isObject, isEmpty } from 'lodash';
import dayjs from 'dayjs';

export const toggleClasses = (el, status, cls) =>
  el && cls && el.classList.toggle(cls, status);

export const getLangLabel = (locale, labels = []) => {
  try {
    if (locale && labels.length > 0) {
      if (locale === 'it') {
        return labels[0];
      }

      if (locale === 'en') {
        return labels[1];
      }

      if (locale !== 'en' || locale !== 'it') {
        throw new Error('Invalid locale');
      }
    }
  } catch (error) {
    console.error('getLangLabel', error?.message);
  }
};

export const parseFormData = (data) => {
  try {
    if (isObject(data) && !isEmpty(data)) {
      const formData = new FormData();
      Object.keys(data).map((k) => {
        const hasKey = data[k]?.hasOwnProperty('value');
        const val = hasKey ? data[k].value : data[k];
        formData.append(k, val);
      });
      return formData;
    }
    return new Error('Data must by filled object');
  } catch (error) {
    console.error('formData', error.message);
  }
};

export const toggleBlurLayout = (cls, status) => {
  const layout = document.querySelector('.layout');
  if (layout && cls && Array.isArray(cls) && cls?.length > 0)
    cls?.map((c) => toggleClasses(layout, status, c));
};

export const shapeCartServerSide = ({ cart, date, hour }) => {
  try {
    if (cart?.length > 0 && date && hour) {
      return cart
        .map((c) => {
          if (c.qty > 1)
            return new Array(c.qty).fill({
              product_id: c.id,
              hour_service: c?.hour_service || hour,
              date_service: dayjs(date).format('YYYY-MM-DD'),
            });

          return {
            product_id: c.id,
            date_service: dayjs(date).format('YYYY-MM-DD'),
            hour_service: c?.hour_service || hour,
          };
        })
        .flat();
    }

    return [];
  } catch (error) {
    console.error('createCartForServer', error);
  }
};

export const shapeCartClientSide = ({
  cart,
  date,
  hour,
  product,
}) => {
  try {
    if (cart?.length > 0 && date && hour) {
      return cart
        .map((c) => {
          if (c.qty > 1)
            return new Array(c.qty).fill({
              // New
              hour_service: c?.hour_service || hour,
              date_service: dayjs(date).format('YYYY-MM-DD'),
              product: {
                id: c.id,
                name: c.product_name,
                price_sale: c.product_price,
                document_required: c.document_required,
                site: product?.site,
                category: product?.category,
              },
            });

          return {
            // New
            hour_service: c?.hour_service || hour,
            date_service: dayjs(date).format('YYYY-MM-DD'),
            product: {
              id: c.id,
              name: c.product_name,
              price_sale: c.product_price,
              category: product?.category,
              document_required: c.document_required,
              site: product?.site,
            },
          };
        })
        .flat();
    }
    return [];
  } catch (error) {
    console.error('createCartForClient', error);
  }
};
