import { Collapsible, Cta, Header, Section } from '@components';

import Link from 'next/link';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import classnames from 'classnames';
import { useRouter } from 'next/router';

export default function Location({
  title,
  label,
  description,
  list,
  cta,
}) {
  const { push, locale } = useRouter();

  return (
    <Section className="">
      <Header
        centered
        fullWidth
        title={title}
        label={label}
        description={description}
        watermark="Location"
      />

      {list?.length > 0 && (
        <div
          className={classnames(
            'flex flex-col',
            'items-center justify-center',
            'mt-16 w-full px-8 lg:mx-auto lg:px-8',
          )}
        >
          {list?.map(({ attributes }, i) => {
            const {
              name,
              address,
              description,
              stores,
              short_description,
            } = attributes;
            return (
              <div
                key={i}
                className={classnames(
                  'flex w-full flex-col space-y-4 pb-12 md:pb-14',
                )}
              >
                <h4
                  className={classnames(
                    'label text-center font-voice text-sm tracking-airy-light text-cream-50 md:text-lg lg:text-xl',
                  )}
                >
                  {name}
                </h4>

                <div className="flex flex-col space-y-2 text-center">
                  <span
                    className={classnames(
                      'text-sm font-light text-primary-300 md:text-lg',
                    )}
                  >
                    {address}
                  </span>

                  <p
                    className={classnames(
                      'paragraph mx-auto flex text-center',
                    )}
                  >
                    {short_description}
                  </p>

                  <Collapsible
                    title={
                      locale === 'it'
                        ? 'Qui puoi trovare'
                        : 'Here you can find'
                    }
                    className="pt-8"
                  >
                    <div className="flex flex-col items-center w-full py-8 mx-auto space-y-6 lg:flex-row lg:justify-center lg:space-x-8 lg:space-y-0">
                      {stores?.data?.map(({ attributes }, i) => {
          
                        return <Link
                          key={i}
                          href={'/' + attributes?.slug}
                          className={classnames(
                            'md:space-x-2',
                            'max-w-lg',
                            'relative flex items-center text-base tracking-wider text-cream-50 md:text-xl',
                            "before:absolute before:block before:h-[2px] before:w-full before:content-['']",
                            'before:-bottom-1 before:-left-0.5 before:bg-primary-500',
                            'before:rounded-md',
                            'before:origin-top-left before:scale-x-0 before:hover:scale-x-100',
                            'before:transition before:duration-300 before:ease-in-out',
                          )}
                        >
                          {attributes?.name?.includes(
                            'Ristorante',
                          ) ||
                            attributes?.name?.includes(
                              'Restaurant',
                            ) ? (
                            <RestaurantIcon
                              fontSize="small"
                              className="absolute opacity-50 md:static -left-8"
                            />
                          ) : (
                            <LocalCafeIcon
                              fontSize="small"
                              className="absolute opacity-50 md:static -left-8"
                            />
                          )}

                          <span>{attributes?.name}</span>
                        </Link>
                        
                      })}
                    </div>
                  </Collapsible>
                </div>
              </div>
            );
          })}
          <Cta
            label={locale === 'it' ? "Scopri" : "Discover More"}
            href="/location"
            className="max-w-xs mx-auto mt-8"
          />
        </div>
      )}
    </Section>
  );
}
