import { Section, Header, Marker } from '@components';
import style from './style.map';
import GoogleMapReact from 'google-maps-react-markers';

export default function StoreOnMap({
  coordinates,
  store,
}) {
  if (!coordinates) return null;

  return (
    <Section
      className="h-[500px] !pt-0"
      fullWidth={true}
      noPadding={true}
    >
      <GoogleMapReact
        className="relative"
        yesIWantToUseGoogleMapApiInternals
        apiKey={process.env.NEXT_PUBLIC_GMAPS}
        defaultCenter={{
          lat: Number(coordinates?.lat),
          lng: Number(coordinates?.lng),
        }}
        //mapMinHeight="100vh"
        defaultZoom={12}
        options={{
          scrollwheel: true,
          disableDefaultUI: true,
          styles: style,
        }}
      >
        <Marker
          store={store}
          {...coordinates}
          lat={Number(coordinates?.lat)}
          lng={Number(coordinates?.lng)}
        />
      </GoogleMapReact>
    </Section>
  );
}
