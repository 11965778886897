import { useTranslation } from 'next-i18next';
import React from 'react';

const EmptyCartMessage = () => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col items-center justify-center my-24 ">
      <h2 className="max-w-xs font-extrabold text-center uppercase text-md font-title md:text-xl">
        {t('cart.empty')}
      </h2>
    </div>
  );
};

export default EmptyCartMessage;
