import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_ADITUS_API + '/stripe';

const prepareHeaders = (access_token) => {
  const headers = {};
  const token = access_token;
  if (token) {
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};
export const validatePayment = async (
  { amount, email, payment_method_id, payment_intent_id },
  access_token,
) => {
  const path = `${baseUrl}/validate-payment`;
  let response = {
    data: null,
    error: false,
  };

  try {
    const headers = prepareHeaders(access_token);
    let body = {};

    if (amount && email && payment_method_id) {
      body = { amount, email, payment_method_id };
    } else if (payment_intent_id) {
      body = { payment_intent_id };
    }

    await axios
      .post(path, body, { headers })
      .then((res) => {
        response = { ...response, data: res.data };
      })
      .catch((error) => {
        response = { ...response, error: error };
      });
  } catch (error) {
    console.error('validatePayment', error);
    return { error };
  }
  return response;
};
