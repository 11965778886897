import classNames from 'classnames';
import { MomentoPin } from '@icons';

export default function Marker({ lat, lng, store }) {
  if (!lat || !lng) return null;

  return (
    <div
      onClick={() => {
        if (store?.map) window.open(store?.map, '_blank');
      }}
      lat={lat}
      lng={lng}
      className={classNames(
        'absolute bottom-0 z-50',
        'flex rounded-full marker',
        'cursor-pointer',
      )}
    >
      <MomentoPin className="w-10 h-10 fill-primary-500" />
    </div>
  );
}
