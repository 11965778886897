import { Header, Section } from '@components';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Value from './Value';
import classnames from 'classnames';
import { useMediaQueries } from '@hooks';

export default function Values({
  title,
  label,
  list,
  description,
}) {
  const { isTablet } = useMediaQueries();

  return (
    <Section className={classnames('')}>
      <div
        className={classnames(
          'container',
          'p-4',
          'mx-auto',
          'space-y-8',
          'text-center',
        )}
      >
        <Header
          centered
          fullWidth
          label={label}
          title={title}
          watermark="cultura"
          description={description}
        />
      </div>

      {isTablet && list?.length > 0 && (
        <Splide
          tag="section"
          options={{
            drag: true,
            type: 'slide',
            width: '100vw',
            height: '100%',
            autoplay: true,
            pagination: true,
            arrows: false,
            padding: 0,
            rewind: true,
            snap: true,
            perPage: 1,
            perMove: 1,
            focus: 0,
            arrows: false,
            flickPower: 800,
            flickMaxPages: 1,
            lazyLoad: 'nearby',
            video: {
              loop: true,
              mute: true,
            },
          }}
        >
          {list?.map(({ name, description }, i) => {
            return (
              <SplideSlide key={i}>
                <Value
                  key={i}
                  name={name}
                  description={description}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )}

      {!isTablet && list?.length > 0 && (
        <div
          className={classnames(
            'container',
            'mx-auto',
            'pt-10',
            'justify-center',
            'bg-[#0000]',
            'grid',
            'gap-4',
            'lg:grid-cols-3',
            'lg:gap-2',
            'xl:grid-cols-3',
          )}
        >
          {list?.map(({ name, description }, i) => {
            return (
              <Value
                key={i}
                name={name}
                description={description}
              />
            );
          })}
        </div>
      )}
    </Section>
  );
}
