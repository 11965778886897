import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useRouter } from 'next/router';
import PhoneIcon from '@mui/icons-material/Phone';

export default function BookingFoodDialog({
  open,
  title,
  setShowFoodDialog,
  phone,
}) {
  const { locale } = useRouter();
  const onClose = () => setShowFoodDialog(false);

  const text = {
    en: 'Request availability for your food experience now, we will contact you to indicate dates and times!',
    it: 'Richiedi subito la disponibilità per la tua esperienza food, ti ricontatteremo per indicarti date e orari!',
  };

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="xs" open={open}>
      <DialogTitle className="p-8 text-xl font-semibold text-center text-white">
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="text-sm">{text[locale]}</p>

        <List dense={true} className='mt-8'>
          <ListItem>
            <ListItemIcon>
              <EmailIcon className="fill-primary-500" />
            </ListItemIcon>
            <ListItemText
              secondary="Email"
              primary={
                <a
                  className="font-semibold underline underline-offset-2"
                  href="mailto:info@momentofood.it"
                >
                  info@momentofood.it
                </a>
              }
            />
          </ListItem>

          {phone && (
            <ListItem>
              <ListItemIcon>
                <PhoneIcon className="fill-primary-500" />
              </ListItemIcon>
              <ListItemText
                secondary="Phone"
                primary={
                  <a
                    className="font-semibold underline underline-offset-2"
                    href={`tel:${phone}`}
                  >
                    {phone}
                  </a>
                }
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}
