import { Cta, RoomFeatures } from '@components';

import Image from 'next/image';
import Link from 'next/link';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { RevealWrapper } from 'next-reveal';
import { useStrapiImageFormat, useMediaQueries } from '@hooks';
import classnames from 'classnames';
import { useRef } from 'react';

export default function BoxesList({
  list,
  isOdd = true,
  showIndex = false,
}) {
  const sectionRef = useRef(null);
  const { isMobile } = useMediaQueries();

  return list?.length > 0 ? (
    <div
      ref={sectionRef}
      className="container mx-auto pb-14 xl:py-28"
    >
      <div className="flex flex-col column-element space-y-28 xl:space-y-48">
        {list?.map((box, i) => {
          const isAligned = isOdd ? i % 2 : i % 2 == 0;

          const {
            title,
            label,
            name,
            description,
            stores,
            address,
            city,
            img,
            cta,
            facilities,
          } = box?.attributes || box;

          const imgSrc = useStrapiImageFormat({
            img,
          });

          return (
            <RevealWrapper
              key={i}
              delay={450}
              reset={true}
              mobile={false}
            >
              <div
                key={i}
                className={classnames(
                  'row-element',
                  'relative w-full',
                  'flex flex-col-reverse justify-around xl:flex-row',
                  {
                    'xl:flex-row-reverse': isAligned,
                  },
                )}
              >
                <div
                  className={classnames(
                    'text-element',
                    'flex flex-col justify-center xl:items-center',
                    'space-y-8 xl:w-1/3',
                    'mt-10 xl:mt-0 xl:px-1',
                  )}
                >
                  <div
                    className={classnames(
                      'flex flex-col space-y-4 xl:space-y-8',
                      {
                        'items-start': isAligned,
                        'items-start xl:items-end': !isAligned,
                      },
                    )}
                  >
                    <div
                      className={classnames('flex flex-col', {
                        'items-start': isAligned,
                        'items-start xl:items-end': !isAligned,
                      })}
                    >
                      <span className="label">{label}</span>
                      <h2
                        className={classnames(
                          'relative max-w-lg',
                          'text-cream-50',
                          'text-2xl md:text-3xl xl:text-5xl',
                          'tracking-widest',
                          {
                            'xl:text-right': !isAligned,
                            'font-voice font-light tracking-airy-light':
                              showIndex,
                            'md:font-medium lg:font-secondary lg:font-bold':
                              !showIndex,
                          },
                        )}
                      >
                        {showIndex && (
                          <span className="mx-2 text-2xl text-primary-500 md:text-4xl">
                            {i + 1}
                          </span>
                        )}
                        {title || name}
                      </h2>

                      <div
                        className={classnames(
                          'mt-6 flex h-0.5 w-28 overflow-hidden rounded-2xl',
                          'from-primary-500 to-white/0',
                          {
                            'rounded-r-x bg-gradient-to-r':
                              isAligned,
                            'rounded-r-x xl:rounded-l-x bg-gradient-to-r xl:bg-gradient-to-l':
                              !isAligned,
                          },
                        )}
                      />
                    </div>

                    {address && <span className="">{address}</span>}

                    <p
                      className={classnames('paragraph', {
                        'text-start': isAligned,
                        'xl:text-end': !isAligned,
                      })}
                    >
                      {description}
                    </p>

                    {facilities && (
                      <RoomFeatures facilities={facilities} />
                    )}

                    {cta && (
                      <Cta
                        {...cta}
                        className="w-full md:max-w-xs"
                      />
                    )}

                    <ul
                      className={classnames('space-y-4', {
                        'md:pl-1': isOdd,
                        'md:pr-1': !isOdd,
                      })}
                    >
                      {stores?.data?.length > 0 &&
                        stores?.data?.map(({ attributes }, y) => {
                          const { name, slug } = attributes;

                          return (
                            <Link
                              key={y}
                              href={slug}
                              className={classnames(
                                'relative flex items-center space-x-2 text-sm tracking-wider text-cream-50 md:text-lg',
                                "before:absolute before:block before:h-[2px] before:w-full before:content-['']",
                                'before:-bottom-1 before:-left-0.5 before:bg-primary-500',
                                'before:rounded-md',
                                'before:origin-top-left before:scale-x-0 before:hover:scale-x-100',
                                'before:transition before:duration-300 before:ease-in-out',
                              )}
                            >
                              {name?.includes('Ristorante') ||
                              name?.includes('Restaurant') ? (
                                <RestaurantIcon
                                  fontSize="small"
                                  className="opacity-50"
                                />
                              ) : (
                                <LocalCafeIcon
                                  fontSize="small"
                                  className="opacity-50"
                                />
                              )}
                              <span>{name}</span>
                            </Link>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div
                  className={classnames(
                    'image-element',
                    'flex justify-center',
                    'relative w-full xl:w-1/3',
                    "before:h-[400px] before:w-10/12 before:content-['']",
                    'before:absolute before:-z-5 before:hidden before:xl:block',
                    'before:top-10 before:bg-primary-50/10',
                    'before:rounded-md',
                    {
                      'before:xl:-right-6': isAligned,
                      'before:xl:-left-6': !isAligned,
                      //'before:animate-pulse': !imgLoaded
                    },
                  )}
                >
                  <Image
                    width={600}
                    height={400}
                    quality={isMobile ? 80 : 100}
                    alt="img"
                    blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAAIAAYDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAcEAABAwUAAAAAAAAAAAAAAAAAAQIDBBEhMaH/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhEDEQA/AKmqnSNuNo63AAOC/9k="
                    placeholder="blur"
                    loader={({ src }) => {
                      if (src) return src;
                    }}
                    priority={true}
                    className="object-cover w-full mx-auto rounded-md aspect-video h-100 xl:aspect-square"
                    src={imgSrc}
                  />
                </div>
              </div>
            </RevealWrapper>
          );
        })}
      </div>
    </div>
  ) : null;
}
