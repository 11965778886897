import { useMediaQueries } from '@hooks';
import { Close, Tune } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  Dialog,
  Button,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

const Filters = ({
  filterData,
  selectedFilters,
  setSelectedFilters,
}) => {
  const { t } = useTranslation();
  const { isTablet, isMobile } = useMediaQueries();
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const citiesReturn = filterData?.cities?.map((elem) => (
    <div
      key={elem}
      className="flex justify-between items-center w-full"
    >
      <span className="max-w-[75%]">
        {elem.replaceAll('-', ' ')}
      </span>
      <Checkbox
        color="primary"
        checked={selectedFilters?.city?.includes(elem)}
        onChange={(res) => {
          if (!res.target.checked) {
            const filtered = selectedFilters?.city?.filter(
              (f) => f !== elem,
            );
            setSelectedFilters({
              ...selectedFilters,
              city: [...filtered],
            });
          } else {
            const filtered = [...selectedFilters?.city];
            filtered.push(elem);
            setSelectedFilters({
              ...selectedFilters,
              city: [...filtered],
            });
          }
        }}
      />
    </div>
  ));
  const locationReturn = filterData?.locations?.map((elem) => (
    <div
      key={elem}
      className="flex justify-between items-center w-full"
    >
      <span className="max-w-[75%]">
        {elem.replaceAll('-', ' ')}
      </span>
      <Checkbox
        color="primary"
        checked={selectedFilters?.location?.includes(elem)}
        onChange={(res) => {
          if (!res.target.checked) {
            const filtered = selectedFilters?.location?.filter(
              (f) => f !== elem,
            );
            setSelectedFilters({
              ...selectedFilters,
              location: [...filtered],
            });
          } else {
            const filtered = [...selectedFilters?.location];
            filtered.push(elem);
            setSelectedFilters({
              ...selectedFilters,
              location: [...filtered],
            });
          }
        }}
      />
    </div>
  ));
  const content = () => {
    return (
      <div className=" capitalize space-y-2  h-screen bg-secondary-800/10 p-8  lg:h-fit  pb-10 lg:pb-8 shadow-card rounded-xl ">
        <div className="w-full flex justify-center">
          <Chip
            variant="outlined"
            label={t(`generic.labels.filter_by`)}
            className=" text-xs rounded-[24px] font-medium w-fit h-auto text-primary-400 uppercase"
            sx={{
              '& .MuiChip-label': {
                padding: '16px 32px !important',
              },
            }}
          />
        </div>
        <div className="font-bold text-primary-400 uppercase">
          {t(`generic.labels.cities`)}
        </div>
        <div>{citiesReturn}</div>
        <div className="font-bold text-primary-400 uppercase">
          {t(`generic.labels.locations`)}
        </div>
        <div>{locationReturn}</div>
      </div>
    );
  };

  if (isTablet) {
    return (
      <>
        <div className="flex w-full justify-end">
          <Button
            variant="text"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            {t(`generic.labels.filter_by`)}
            <Tune
              color="primary"
              fontSize="small"
              className="ml-2"
            />{' '}
          </Button>
        </div>

        <Dialog
          open={openDialog}
          onClose={() => handleDialogClose()}
          fullScreen={isMobile}
        >
          <div className="absolute top-9 right-8">
            <IconButton onClick={() => handleDialogClose()}>
              <Close htmlColor="#fff" />
            </IconButton>
          </div>
          {content()}

          <Button
            className="absolute bottom-0 text-white"
            fullWidth
            sx={{
              backgroundColor: '#e17f38 !important',
              '&:hover': {
                backgroundColor: '#da5f06 !important',
              },
            }}
            onClick={() => handleDialogClose()}
          >
            {t('buttons.save')}
          </Button>
        </Dialog>
      </>
    );
  }

  return content();
};

export default Filters;
