import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import { useProgressiveImage } from '@hooks';

export default function LazyImage({ img, className, skeletonCls }) {
  const isLoaded = useProgressiveImage(img);

  if (!isLoaded) {
    return (
      <div
        className={classnames(
          skeletonCls,
          'skeleton',
          'm-0 p-0',
          '!h-full !w-full',
          'flex animate-pulse bg-white/30',
        )}
      />
    );
  }

  return <LazyLoadImage src={img} className={className} />;
}
