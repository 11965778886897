import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import RemoveIcon from '@mui/icons-material/Remove';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

export default function QuantitySelector({
  onRemoveCallback,
  onAddCallback,
  onResetCallback,
  limitReached,
  quantity,
  minAllowed = 0,
  alert,
  slots,
  onClickSlot,
  currentProduct,
  // Uso hour scelto dall'utente per disabilitare alcuni prodotti che hanno degli slot differenti e quindi non risultano acquistabili
  hour,
  date,
  minPax,
}) {
  const [count, setCount] = useState(quantity);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slotSelected, setSlotSelected] = useState(null);
  const { t } = useTranslation('common');
  const ticketsLang = 'drawers.book_product.steps.tickets.';

  const onAdd = () => {
    // const quantityToAdd = count <= 0 ? (minPax || 1) : 1;
    if (!limitReached) {
      setCount(count + 1);
    }
    // moved from if to allow show alert for maximum ticket reached
    if (onAddCallback) onAddCallback(count + 1);
  };

  const onRemove = () => {
    if (count > 0 && count > minAllowed) {
      setCount(count - 1);
      if (onRemoveCallback) onRemoveCallback(count - 1);
    }
  };

  const onReset = () => {
    if (count > 0 && !alert) {
      if (onResetCallback) onResetCallback(count);
      setCount(0);
    }
  };

  const checkSlotsDisponibility = (slots) => {
    if (slots?.length > 0) {
      return slots.filter((s) => {
        return s >= hour;
      });
    }

    return [];
  };

  useEffect(() => {
    if (slots?.length > 0) {
      const filteredSlots = checkSlotsDisponibility(slots);

      setAvailableSlots([...filteredSlots]);

      if (!slotSelected) {
        setSlotSelected(filteredSlots?.[0]);
      }
    }
  }, [slots, count, minAllowed]);

  useEffect(() => {
    if (currentProduct) {
      onClickSlot(slotSelected);
    }
  }, [slotSelected, currentProduct]);

  return (
    <>
      <div
        className={classNames(
          'relative',
          'select-none',
          'w-full py-4',
          'flex flex-row items-center justify-around',
          {
            'pointer-events-none opacity-10':
              slots?.length > 0 && availableSlots?.length === 0,
          },
        )}
      >
        <Button
          size="small"
          onClick={onRemove}
          disabled={count === 0}
          className="p-0 m-0 text-slate-400"
        >
          <RemoveIcon fontSize="small" />
        </Button>
        <span
          onClick={onReset}
          className={classNames(
            'md:w-20 w-12',
            'text-medium md:text-xl',
            'font-medium md:font-bold',
            'cursor-pointer text-center',
            { 'text-primary': limitReached },
          )}
        >
          {count}
        </span>
        <Button
          size="small"
          onClick={onAdd}
          className="p-0 m-0 text-slate-400"
          color={limitReached ? 'warning' : 'primary'}
        >
          <Add fontSize="small" />
        </Button>

        {alert && minAllowed == count && (
          <span
            className={classNames(
              'w-full',
              'absolute -bottom-1',
              'text-xs text-center',
              'text-gray-700 dark:text-white',
            )}
          >
            {t(ticketsLang + 'already_booked.text')}
            <Link href="/carrello" passHref>
              <span className="mx-1 font-medium underline">
                {t(ticketsLang + 'already_booked.link')}
              </span>
            </Link>
          </span>
        )}
      </div>

      {/* La presenza di slots significa che si tratta di suggerimenti, 
      nello step di cross Selling, che necessitano di una select */}

      {count > minAllowed && availableSlots?.length > 0 && (
        <div className="flex w-full mt-1">
          {/* <code>
            <pre>
              {JSON.stringify(
                {
                  currentProduct: currentProduct?.hour_service,
                  slot: availableSlots[0],
                  slotSelected,
                  name: currentProduct?.slug,
                  minAllowed,
                  minPax,
                  count, 
                  quantity
                },
                null,
                2,
              )}
            </pre>
          </code> */}

          <FormControl fullWidth className="flex w-full">
            <InputLabel>Time Slots</InputLabel>
            <Select
              required
              size="small"
              label="Time Slots"
              className="p-0 m-0"
              value={slotSelected}
              onChange={(e) => {
                setSlotSelected(e.target.value);
              }}
            >
              {availableSlots.map((slot, i) => (
                <MenuItem value={slot} key={i} className="text-sm">
                  {slot}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}
